import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IContacts } from '../Interfaces/contacts.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  host = 'Contacts';
  constructor(
    private httpClient: HttpClient,
  ) { }


  //contactsInfo: Contacts;
fetchContact(){
  return this.httpClient.get<IContacts>(`${environment.BaseUrl}/${this.host}`).pipe(
    map((contacts: IContacts) => {
      var contactsInfo;
      return contactsInfo={
        facebookUserId: contacts.facebookUserId,
  facebook: contacts.facebook,
  instagram: contacts.instagram,
  youtube: contacts.youtube,
  photoUrl: contacts.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/Photos?OrderId='+ contacts.photos[0].orderId: '',
  photoOrderId: contacts.photos[0].orderId,
}
    }
    )
  )
}


updatePhoto(dataInfo) {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/Photos`, {
    data: dataInfo.data,
    name: dataInfo.name,
    extention: dataInfo.extention,
    orderId: dataInfo.orderId
  }, {
    headers
  })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
}




updateContacts(data) {
  const { facebookUserId, facebook, instagram, youtube } = data;
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url = `${environment.BaseUrl}/${this.host}`;
  this.httpClient.put(url,  data, { headers })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
}



}