import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent implements OnInit, OnDestroy {
  employeeSubscription?: Subscription;

  constructor(
    private employeeService: EmployeeService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    this.employeeSubscription.unsubscribe();
  }

  employeeList = [];
  ngOnInit(): void {
    this.employeeSubscription = this.employeeService
      .getEmployeeList()
      .subscribe((data) => {
        data.forEach((element) => {
          this.employeeList.push(element);
        });
      });
  }

  removeEmployee(id: number) {
    this.employeeService.deleteEmployeeById(id);
  }

  redirectToCreateEmployee() {
    this.router.navigateByUrl('employee-create');
  }
}
