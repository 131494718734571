import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { BranchService } from '../services/branch.service';
import { ContactsService } from '../services/contacts.service';
import { LoginService } from '../services/login.service';
import { NavbarService } from '../services/navbar.service';
import { LanguageService } from '../services/shared-services/language.service';
import { Contacts } from '../shared/models/contacts.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  contactSubscription?: Subscription;
  branchSubscription?: Subscription
  subscriptions: Subscription[] = [];
  centered = false;
  disabled = false;
  unbounded = false;


  navbar;
  number;

  constructor(
    private languageService: LanguageService,
    private navbarService: NavbarService,
    private contactsService: ContactsService,
    private branchService: BranchService,
    private router: Router,
    private loginService: LoginService

  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  show = false;
  ngOnInit(): void {
    this.navbar = this.navbarService.getNavbar();

    if (localStorage.getItem('token')) {
      this.show = true
    }
    this.fetchSocMedia();
    this.getnumber();
  }

  public isCollapsed = true;


  socialContact?: Contacts;

  fetchSocMedia() {
    this.contactSubscription = this.contactsService.fetchContact().subscribe((element) => {
      this.socialContact = element;
    })
    this.subscriptions.push(this.contactSubscription)
  }

  getnumber() {
   this.branchSubscription = this.branchService.getMainBranch().subscribe((element) => {
      this.number = element?.phoneNumbers[0];
    })
    this.subscriptions.push(this.branchSubscription)
  }

  clickEvent(value) {
    this.languageService.checkLanguage(value);
  }
  redirectToProducts() {
    this.router.navigateByUrl('product-list');
  }

  redirectToAbout() {
    this.router.navigateByUrl('about-update');
  }

  redirectToServices() {
    this.router.navigateByUrl('services-update');
  }
  redirectToEmployees() {
    this.router.navigateByUrl('employee-list');
  }
  redirectToContacts() {
    this.router.navigateByUrl('contact-list');
  }
  redirectToInstalments() {
    this.router.navigateByUrl('instalment-list');
  }
  redirectToColors() {
    this.router.navigateByUrl('color-list');
  }
  redirectToCompositions() {
    this.router.navigateByUrl('composition-list');
  }
  redirectToSocMedia() {
    this.router.navigateByUrl('contacts');
  }
  redirectToHomeHeaders() {
    this.router.navigateByUrl('homeHeaders');
  }

  logOut() {
    //this.loginService.logOut()
    // .subscribe(result => {
    //   console.log(result);
    //   window.location.href='';
    // window.location.href='';
    this.router.navigateByUrl('');
    this.loginService.logOut()
    //}) 
    //this.router.navigateByUrl('');
  }
}
