<section id="display-size">
    <div class='card'>
        <div class='card-header'>
            <p id="p1">
                Product List
            </p>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-md-2'>
                    <button style="margin-bottom: 20px" (click)="redirectToCreateProd()"> Create New Product</button>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>

                        <th>ID</th>
                        <th> Title</th>
                        <th>
                            Original Value
                        </th>
                                          </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let product of productList;  let i=index'>
                        <th>{{product.id}}</th>
                        <th> {{product.name}}</th>
                        <th>
                            {{product.originalValue}}
                        </th>
                        <th>
                            <img style="width: 80px;" [src]='product.photoUrl'>
                        </th>
                        <th>
                            <button [routerLink]="['/product-update', product.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removeProduct(product.id)"> DELETE</button>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <mat-paginator [length]="productList[0].totalCount" [pageSize]="10" (page)="onPaginateChange($event)">
    </mat-paginator>
</section>