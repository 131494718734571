export class ProductDetails {
  id?: number;
  name?: string;
  description?: string;
  colors?: string[];
 compositions?: string[];
  // productType?: string;
  // calcualtorId?: number;
 // dimentions: IDimentions;
 width?: number;
    height?: number;
  //prices: IPrices;
  originalValue?: number;
   salePercentage?: number;
   showSalePercentage?: boolean;
  saleValue?: number;
 saledValue?: number;
  photoUrl?: string;
}
