import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IColor } from '../admin-panel/admin/Interfaces/color.interface';
import { Color} from '../shared/models/color.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  host = 'Colors/?orderby=id enc';
  currentLanguage;

  constructor(
    private hhtp: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
   }

  getColors(): Observable<Color[]>{
    return this.hhtp
    .get<IColor[]>(`${environment.BaseUrl}/${this.host}`)
    .pipe(
      map((colors) =>{
        return colors.map((color) => {
          var colorList = new Color();
          return colorList={
            id: color.id,
            name: color.names.find(x => x.language.id==this.currentLanguage)?.value ?? '',
          }
        }
       )
      }
      ),tap(x => console.log(x))
    )
  }
  
  
  }
  