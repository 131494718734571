<section id="display-size">
        <div class='card'>
        <div class='card-header'>
            <p id="p1">
                Contact List
            </p>
        </div>
        <div class='card-body'>
            <div class='row'>
                             <div class='col-md-2'>
                    <button style="margin-bottom: 20px" (click)="redirectToCreateContact()">Create New Contact</button>
                </div>
            </div>
        
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th> Title</th>
                        <th>
                            Address
                        </th>
                                          </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let branch of branchList;  let i=index'>
                        <th>{{branch.id}}</th>
                        <th> {{branch.title}}</th>
                        <th>
                            {{branch.address}}
                        </th>
                        <th>
                            <img style="width: 80px;" [src]='branch.photoUrl'>
                        </th>
                        <th>
                            <button [routerLink]="['/contact-update', branch.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removebranch(branch.id)"> DELETE</button>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>