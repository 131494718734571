export class Employee {
  id: number;
  orderId: number;
  fullName: string;
  position: string;
  photoUrl: string;
}



