import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageService } from '../../../services/shared-services/language.service';


@Injectable({
  providedIn: 'root'
})
export class ProductNameService {
  currentLanguage;

  host='http://localhost:5000/api/v1/Products/'
  constructor(private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }


  putName(data){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.put(`${this.host}/${data.id}/Names`, {data}, {headers}).subscribe({
      next: data => {
        console.log(data);
    },
    error: error => {
        console.error('There was an error!', error);
    }
    })
  }

  // postColor(data){
  //   const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  //   this.httpClient.post(`${this.host}/{data.id}/Names`, {colors: data.colors}, {headers}).subscribe({
  //     next: data => {
  //         console.log(data);
  //     },
  //     error: error => {
  //         console.error('There was an error!', error);
  //     }
  // });
  // }
}
