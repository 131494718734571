import { Component, OnDestroy, OnInit } from '@angular/core';
import { AboutServiceService } from '../services/about-service.service';
import { Employee } from '../shared/models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { About } from '../shared/models/about.model';
import { TitlesService } from '../services/titles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent implements OnInit, OnDestroy {
  titles;
  aboutSubscription?: Subscription;
  employeeSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private aboutServiceService: AboutServiceService,
    private employeeService: EmployeeService,
    private titlesService: TitlesService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchAbout();
    this.fetchEmployees();
    this.titles = this.titlesService.getTitles();
  }

  aboutInfo: About;

  fetchAbout() {
    this.aboutSubscription = this.aboutServiceService.getAbout().subscribe((data) => {
      this.aboutInfo = {
        description: data.description,
        photoUrl: data.photoUrl,
      }
    });
    this.subscriptions.push(this.aboutSubscription);
  }

  members: Employee[] = [
  ];

  fetchEmployees() {
    this.employeeSubscription = this.employeeService.getEmployees().subscribe((data) => {
      data.forEach((element) => {
        this.members.push(element);
      })
    });
    this.subscriptions.push(this.employeeSubscription);
  }
}
