import { Injectable } from '@angular/core';
import { LanguageService } from './shared-services/language.service';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  currentLanguage;
  filterData;

  filterDataList = [{
    'filterTitle': 'ფილტრი',
    'search': 'ძებნა',
    'title': 'დასახელება',
     'price': 'ფასი',
    'from': 'დან',
    'to':'მდე',
    'color': 'ფერი',
    'composition': 'მასალა',
  },
  {
    'filterTitle': 'FILTER',
    'search': 'SEARCH',
    'title': 'TITLE',
     'price': 'PRICE',
    'from': 'FROM',
    'to':'TO',
    'color': 'COLOR',
    'composition': 'COMPOSITION',
  }
  ];


  constructor(private languageService: LanguageService) {

    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  getFilterData() {
    if(this.currentLanguage==1) {
return this.filterData=this.filterDataList[0];
}
else{
return  this.filterData=this.filterDataList[1];
}
}


}
