<section class="aboutPage-section">

  <img class="banner"
  [src]="aboutInfo?.photoUrl"
  alt="New Doors - AboutUs Banner"
/>
  <div class="about">
    <div class="title-text">
      <h3><b>{{titles.about}}</b></h3>

      <hr class="title-hr" />
    </div>
    <div class="text">
      <div [innerHtml]='aboutInfo?.description'></div>
</div>

      </div>





  <div class="team">
    <div class="title-text">
      <h3><b>{{titles.team}}</b></h3>
      <hr class="title-hr"/>
    </div>

    <div fxLayout.xs="column" fxLayout="row wrap">
      <span fxFlex.sm="0 1 calc(50%)" fxFlex.md="0 1 calc(33.3%)" fxFlex.gt-md="0 1 calc(33.3%)" *ngFor="let member of members;">

        <img [src]="member.photoUrl" />
          <div class="team-text">
          <h5>{{member.fullName}}</h5>
          <p>{{member.position}}</p>
        </div>
        </span>
    </div>

  </div>
</section>
