import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeHeaderService } from '../services/home-header.service';

@Component({
  selector: 'app-home-header-create',
  templateUrl: './home-header-create.component.html',
  styleUrls: ['./home-header-create.component.scss']
})
export class HomeHeaderCreateComponent implements OnInit {
  form;
  constructor(
    private fb: FormBuilder,
    private homeHeaderService: HomeHeaderService,
  ) { }

  ngOnInit(): void {
    
    this.form = this.fb.group({
      orderId: '',
      
      titles: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1
        }),
        this.fb.group({
          value: '',
          languageId: 2
        })
      ]),
    
      descriptions: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1
        }),
        this.fb.group({
          value: '',
          languageId: 2
        })
      ]),
  
  
      photos: this.fb.array([ 
        this.fb.group({
        data: "string",
        name: "string",
        extention: "png",
        orderId: 0
      }),
    ]),
    })
  }




  //photos
photoUrl;
handleInputChange(e) {
  var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  var pattern = /image-*/;
  var reader = new FileReader();
  if (!file.type.match(pattern)) {
    alert('invalid format');
    return;
  }
  reader.onload = this._handleReaderLoaded.bind(this);
  reader.readAsDataURL(file);
}
_handleReaderLoaded(e) {
  let reader = e.target;
  var picToShow = reader.result;
  this.photoUrl=picToShow;
 
  const base64File = reader.result.split(',')[1];
  this.form.controls.photos.patchValue([
    {data: base64File}]);
  console.log(base64File);

  }


  onSubmit(data){
    this.homeHeaderService.createHomeHeaders(data);
  }
}
