import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/shared-services/language.service';
//fb
import { FacebookService, InitParams } from 'ngx-facebook';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NewDoors';

constructor(
private languageService: LanguageService,
private facebookService: FacebookService
) {

}

  ngOnInit():void{
this.languageService.setLanguage();
this.initFacebookService();
  }
     

     private initFacebookService(): void {
      const initParams: InitParams = { xfbml:true, version:'v3.2'};
      this.facebookService.init(initParams);
    }
  

}
