<!-- <section id="Instalment-update">
<p>
  <b>{{instalmentId}}</b>
</p>


<div class="image-upload">
  <div [formGroup]='photoForm'>
    <img [src]="photoUrl" />
    <input name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
  </div>
</div>

<form [formGroup]='descForm' (ngSubmit)="editDesc(descForm.value)">
  <div formArrayName="descriptions" *ngFor="let description of descriptions?.controls; let i=index">
    <p> აღწერა - {{description.value.language.name}}</p>
    <div [formGroupName]=i>
      <input formControlName="value">
     
    </div>
    <button style="font-size: 16px" mat-stroked-button type="submit"
      (click)="getFormLanguageIdDesc(description.value.language.id)">
      რედაქტირება
    </button>
  </div>
</form>
</section> -->




<section id="Instalment-update">
  <p>
    <b>{{instalmentId}}</b>
  </p>
  
  
  <div class="image-upload">
    <div [formGroup]='photoForm'>
      <img [src]="photoUrl" />
      <input name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
    </div>
  </div>
  
  <form [formGroup]='descForm' (ngSubmit)="editDesc(descForm.value)">
    <div formArrayName="descriptions" *ngFor="let description of descriptions?.controls; let i=index">
      <p> აღწერა - {{description.value.language.name}}</p>
      <div [formGroupName]=i>
        <quill-editor formControlName="value"></quill-editor>
       
      </div>
      <button style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageIdDesc(description.value.language.id)">
        რედაქტირება
      </button>
    </div>
  </form>
  </section>