import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompositionService } from '../services/composition.service';

@Component({
  selector: 'app-composition-create',
  templateUrl: './composition-create.component.html',
  styleUrls: ['./composition-create.component.scss']
})
export class CompositionCreateComponent implements OnInit {

  constructor(   
    private fb: FormBuilder,
    private compositionService: CompositionService,
    ) { }

    form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      orderId: '',
   
      names: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1
        }),
        this.fb.group({
          value: '',
          languageId: 2
        })
      ]),
  })

  }


  onSubmit(data){
    this.compositionService.createComposition(data);
  }
}
