import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {




  constructor() { }

setLanguage(){
  var currentLanguage = this.getCurrentLanguageId();
  if(currentLanguage == undefined || currentLanguage == null || currentLanguage == '')
      localStorage.setItem('currentLanguageId', '1')
}
getCurrentLanguageId(){
  return localStorage.getItem('currentLanguageId');
}
  checkLanguage(value){
    if(value==='eng'){
     // localStorage.removeItem('currentLanguageId');
      localStorage.setItem('currentLanguageId', '2')
    }else if((value==='geo')) {
    //  localStorage.removeItem('currentLanguageId');
      localStorage.setItem('currentLanguageId', '1')
    }
    window.location.reload();
  }




}
