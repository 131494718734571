import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAbout } from '../admin-panel/admin/Interfaces/about-interface';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { About } from '../shared/models/about.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';



interface aboutInput {
  languageId: number,
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class AboutServiceService {
  host = 'AboutUs';
  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  currentLanguage;
  aboutInfo?: About;
  getAbout(): Observable<About> {
    return this.httpClient.get<IAbout>(`${environment.BaseUrl}/${this.host}`).pipe(
      map(res => {
        return this.aboutInfo = {
          languageId: res.descriptions.find(x => x.language.id == this.currentLanguage)?.language ?? '',
          description: res.descriptions.find(x => x.language.id == this.currentLanguage)?.value ?? '',
          shortDescription: res.shortDescriptions.find(x => x.language.id == this.currentLanguage)?.value ?? '',
          photoUrl: res.photos.length > 0
            ? environment.BaseUrl + '/' + this.host +
            '/Photos?OrderId=' +
            res.photos[0].orderId
            : '',

        }
      })
    )
  }


  // getAbout(): Observable<About> {
  //   return this.httpClient.get<IAbout>(`${this.host}`).pipe(
  //     map(res => {
  //       return this.aboutInfo = {
  //         description: res.descriptions.find(x => x.language.id = this.getCurrentLanguage())?.value ?? '',
  //         shortDescription: res.shortDescriptions.find(x => x.language.id = this.getCurrentLanguage())?.value ?? '',
  //         photoUrl: res.photos.length > 0
  //           ? this.host +
  //           '/Photos?OrderId=' +
  //           res.photos[0].orderId
  //           : '',

  //       }
  //     })
  //   )
  // }




  putAbout(formData: aboutInput) {
    const { value, languageId } = formData;
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` }


    return this.httpClient.put<aboutInput>(`${this.host}/Descriptions`, {
      formData
      // value,
      // languageId
    }, {
      headers
    })
      .pipe(
        catchError(error => throwError(error))
      );
  }

}
