import { Injectable } from '@angular/core';
import { LanguageService } from './shared-services/language.service';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsDataService {
  currentLanguage;
  prodDetailsData;

  prodDetailsDataList = [{
    'title': 'კარის N',
    'size': 'ზომა',
    'description': 'აღწერა',
    'compositions': 'შემადგენლობა',
    'color':'ფერი',
    'price': 'ფასი',
   
  },
  {
    'title': 'Door N',
    'size': 'Size',
     'description': 'Description',
    'compositions': 'Composition',
    'color':'Color',
    'price': 'Price',
  }
  ];
  constructor(private languageService: LanguageService) {

    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

 getProdDetailsData() {

    if(this.currentLanguage==1) {
return this.prodDetailsData=this.prodDetailsDataList[0];
}
else{
return  this.prodDetailsData=this.prodDetailsDataList[1];
}
}

}
