import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductList } from '../models/productList.model';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  productsSubscription: Subscription;

  constructor(
    private productService: ProductService,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.productsSubscription.unsubscribe();
  }

  productList: ProductList[] = [];

  ngOnInit(): void {
    this.fetchProductList();
  }

  index;
  onPaginateChange(event) {
    console.log(event.pageIndex);
    this.index = event.pageIndex + 1;
    this.fetchProductList(this.index);
  }

  fetchProductList(pageNumber?: number) {
    this.productList = [];
    this.productsSubscription = this.productService.getProductList(pageNumber).subscribe(data => {
      data.forEach(element => {
        this.productList.push(element);
      })
    })
  }

  removeProduct(id: number) {
    this.productService.deleteProductById(id);
  }
  redirectToCreateProd() {
    this.router.navigateByUrl('product-create');
  }
}
