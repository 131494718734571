<!-- <section id="Color-create">

    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

        <p>orderId</p>
        <input type="number" formControlName="orderId">

        <div formArrayName="names">
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input formControlName="value">
            </div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input formControlName="value">
            </div>

        </div>

        <button type="submit" id="SButton">
            Submit
        </button>
    </form>


</section> -->





<!-- <section id="Color-create" id="display-size" >

    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

        <div formArrayName="names">
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input formControlName="value">
            </div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input formControlName="value">
            </div>

        </div>

        <button type="submit" id="SButton">
            Submit
        </button>
    </form>

</section> -->



<!-- <section id="Color-create">

    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

        <p>orderId</p>
        <input type="number" formControlName="orderId">

        <div formArrayName="names">
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input formControlName="value">
            </div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input formControlName="value">
            </div>

        </div>

        <button type="submit" id="SButton">
            Submit
        </button>
    </form>

</section> -->




<section id="Color-create" id="display-size" >

    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

        <!-- <p>orderId</p>
        <input type="number" formControlName="orderId"> -->

        <div formArrayName="names">
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input formControlName="value">
            </div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input formControlName="value">
            </div>

        </div>

        <button type="submit" id="SButton">
            Submit
        </button>
    </form>

</section>

