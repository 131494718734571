import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/shared-services/language.service';
import { environment } from 'src/environments/environment';
import { IComposition, UpdateComposition } from '../Interfaces/composition.interface';
import { Composition } from '../models/composition.model';

@Injectable({
  providedIn: 'root'
})
export class CompositionService {
  host = 'Compositions';
  currentLanguage;

  constructor(private httpClient: HttpClient,
    private languageService: LanguageService
    ) {
      this.currentLanguage = Number(this.languageService.getCurrentLanguageId());

     }



  compositionList: Composition;
  getCompositions(){
    return this.httpClient.get(`${environment.BaseUrl}/${this.host}`).pipe(
      map((compositions: Array<IComposition>) =>
    
      {
        return compositions.map((composition) => {
          // var colorList = new Color();
          return this.compositionList={
            id: composition.id,
            name: composition.names.find(x => x.language.id==this.currentLanguage)?.value ?? '',
          }
        }
       )
      }
      )
    )
  }
  postComposition(data){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}/{data.id}/Names`, {colors: data.colors}, {headers}).subscribe({
      next: data => {
          console.log(data);
      },
      error: error => {
          console.error('There was an error!', error);
      }
  });
  }

getCompositionById(id: number){
  return this.httpClient
  .get<IComposition>(`${environment.BaseUrl}/${this.host}/${id}`)
  .pipe(
    map((color) => {
      var Color;
      return Color= {
        id: color.id,
        names: color.names.map(x => x ?? '')
      }
    })
  )
}

updateComposition(id, data) {
 const { value, languageId, } = data;
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url = `${environment.BaseUrl}/${this.host}/${id}/Names`;
  this.httpClient.put(url,  data , { headers })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
}


createComposition(data: UpdateComposition) {
     const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`,  data, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  removeComposition(id: number){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, {headers})
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }
}


