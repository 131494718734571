<section   id="homeHeader-update">
  <p>
    <b>{{homeHeaderId}}</b>
  </p>
  
  <section>
  <div class="image-upload">
    <div [formGroup]='photoForm'>
      <img [src]="photoUrl" />
     
    </div>
  </div>
</section>
<section id="display-size">
<input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
  <form [formGroup]='titleForm' (ngSubmit)="editTitle(titleForm.value)">
      <div formArrayName="titles" *ngFor="let title of titles?.controls; let i=index">
        <p> აღწერა - {{title.value.language.name}}</p>
        <div [formGroupName]=i>
          <input id="input1" formControlName="value">
         
        </div>
        <button style="font-size: 16px" mat-stroked-button type="submit"
          (click)="getFormLanguageIdDesc(title.value.language.id)">
          რედაქტირება
        </button>
      </div>
    </form>

  <form [formGroup]='descForm' (ngSubmit)="editDesc(descForm.value)">
    <div formArrayName="descriptions" *ngFor="let description of descriptions?.controls; let i=index">
      <p> აღწერა - {{description.value.language.name}}</p>
      <div [formGroupName]=i>
        <input id="input1" formControlName="value">
       
      </div>
      <button style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageIdDesc(description.value.language.id)">
        რედაქტირება
      </button>
    </div>
  </form>
  </section>
</section>
