import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ServiceService } from 'src/app/admin-panel/admin/services/service.service';
@Component({
  selector: 'app-service-update',
  templateUrl: './service-update.component.html',
  styleUrls: ['./service-update.component.scss'],
})
export class ServiceUpdateComponent implements OnInit, OnDestroy {
  serviceSubscription?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private serviceService: ServiceService
  ) {}

  ngOnDestroy(): void {
    this.serviceSubscription.unsubscribe();
  }

  descForms = [];
  photoUrl;

  ngOnInit(): void {
    this.fetchAboutInfo();
  }

  fetchAboutInfo(): void {
    this.serviceSubscription = this.serviceService
      .getService()
      .subscribe((element) => {
        element.descriptions.forEach((x) => {
          const descForm = this.formBuilder.group({
            value: x.value,
            language: x.language,
          });

          descForm.controls.value.setValue(x.value);
          descForm.controls.language.setValue(x.language);
          this.descForms.push(descForm);
        });

        this.photoUrl = element.photoUrl;
      });
  }

  onDescriptionUpdate(form): void {
    const updateModel = {
      value: form.controls.value.value,
      languageId: form.controls.language.value.id,
    };
    this.serviceService.UpdateServiceDescription(updateModel);
    this.descForms = [];
    this.fetchAboutInfo();
  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.photoUrl = reader.result;
    const base64File = reader.result.split(',')[1];
    console.log(base64File);
    var photo = {
      data: base64File,
      name: 'string',
      extention: 'string',
      orderId: 0,
    };
    this.serviceService.updatePhoto(photo);
  }
}
