import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColorService } from '../services/color.service';

@Component({
  selector: 'app-color-create',
  templateUrl: './color-create.component.html',
  styleUrls: ['./color-create.component.scss']
})
export class ColorCreateComponent implements OnInit {

  constructor( 
     private fb: FormBuilder,
     private colorService: ColorService,
    ) 
    { }

  form: FormGroup;
  
  ngOnInit(): void {
    this.form = this.fb.group({
      // orderId: '',
   
      names: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1
        }),
        this.fb.group({
          value: '',
          languageId: 2
        })
      ]),
  })

}

onSubmit(data){
  this.colorService.createColor(data);
}
}