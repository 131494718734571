<section id="display-size">
  <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

    <div style="max-width: 500px" class="image-upload">
      <img style="max-width: 500px" [src]="photoUrl" />
      <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
    </div>
    <p> Main Branch: </p> <br> <input id="input1" formControlName='isMain'> <br>
    <p> Order Id: </p> <br><input id="input1" formControlName='orderId'>
    <div formArrayName="titles">
      <p> დასახელება</p>
      <div [formGroupName]=0>
        <input id="input1" formControlName="value">
      </div>
      <p>TITLE</p>
      <div [formGroupName]=1>
        <input id="input1" formControlName="value">
      </div>
    </div>


    <div formArrayName="addresses" *ngFor="let address of getAddresses(form); let i=index">
      <div [formGroupName]="0">
        <p> Latitude </p> <br> <input id="input1" formControlName="latitude" /> <br>
        <p>Longitude </p> <br> <input id="input1" formControlName="longitude" />
        <div formArrayName="values">
          <div *ngFor="let value of address.get('values').controls; let j=index">
            <div [formGroupName]="j">
              <span *ngIf="value?.value?.languageId===1">
                <p> მისამართი ქართულად </p>
              </span>
              <span *ngIf="value?.value?.languageId===2">
                <p> Address </p>
              </span> <br>
              <input id="input1" formControlName="value" />
            </div>
          </div>
        </div>
      </div>
      <button id="bottombuttons" style="font-size: 16px" mat-stroked-button type="submit">
        რედაქტირება
      </button>
    </div>
    <p>Phone Numbers </p>
    <button id="PlusButton" (click)="addPhoneNum()"><i class="fa fa-plus" aria-hidden="true"></i></button>
    <div formArrayName="phoneNumbers" *ngFor="let number of phoneNumbers.controls; let i=index">
      <div [formGroupName]=i>
        <p> მობილურის ნომერი: </p> <input id="input1" formControlName='value'>

        <p> თანმიმდევრობა: </p> <input id="input1" formControlName='orderId'>
        <button (click)="removePhoneNum(i)" id=TrashButton><i class="fa fa-trash fa-lg"></i></button>
      </div>
    </div>
    <br>
    <p>Emails </p>
    <button id="PlusButton" (click)="addEmail()"><i class="fa fa-plus" aria-hidden="true"></i></button>
    <div formArrayName="emails" *ngFor="let email of emails.controls; let i=index">
      <div [formGroupName]=i>
        <p> იმეილი:</p> <input id="input1" formControlName='value'>
        <p> თანმიმდევრობა: </p> <input id="input1" formControlName='orderId'>
        <button (click)="removeEmail(i)" id="TrashButton"><i class="fa fa-trash fa-lg"></i></button>
      </div>
    </div>
    <br>
    <p>Working Hours</p>
    <button id="PlusButton" (click)="addworkingHour()"><i class="fa fa-plus" aria-hidden="true"></i></button>
    <div formArrayName="workingHours" *ngFor="let workingHour of workingHours.controls; let i=index">
      <div [formGroupName]=i>
        <p> სამუშაო საათი: </p> <input id="input1" formControlName='value'>
        <p> თანმიმდევრობა: </p> <input id="input1" formControlName='orderId'>
        <button (click)="removeworkingHour(i)" id="TrashButton"><i class="fa fa-trash fa-lg"></i></button>
      </div>
    </div>
    <br>
    <button itemid="SButton" type="submit" id="SButton">
      Submit
    </button>
  </form>
</section>