export class ProductList {
  totalCount: number;
    id: number;
    photoUrl: string;
    // names?: string[];
    name?: string;
    salePercentage?: number;
    showSalePercentage?: boolean;
    originalValue?: number;
    saledValue?: number;
    saleValue?: number;
  }
  