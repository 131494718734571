import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from './shared-services/language.service';
import { ISubscriber } from '../admin-panel/admin/Interfaces/subscriber.interface';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

class Subscriber {
  email: string
}

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  host = 'Subscribers';
  currentLanguage;
  errorMessage;
  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  postSubscribers(email?: any) {
    const subscribeMobel = new Subscriber();
    subscribeMobel.email = email;
    let headers =
      new HttpHeaders({ 'Content-type': 'application/json' });
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`, subscribeMobel, { headers: headers }).subscribe({
      next: data => {
        console.log(data)
      },
      error: error => {
        this.errorMessage = error.message;
        console.error('There was an error!', error);
      }
    });
  }

}
