import { Injectable } from '@angular/core';
import { LanguageService } from './shared-services/language.service';

@Injectable({
  providedIn: 'root'
})
export class TitlesService {
  titles;
  currentLanguage;

  titleList = [{
    'about': 'ჩვენს შესახებ',
    'prods': 'პროდუქტები',
    'service': 'სერვისები',
    'instalments': 'განვადება',
    'team': 'ჩვენი გუნდი',
    'subscribe': 'სიიახლეების გამოწერა',
    'contacts': 'კონტაქტი',
  },
  {
    'about': 'ABOUT US',
    'prods': 'PRODUCTS',
    'service': 'SERVICES',
    'instalments': 'INSTALMENT',
    'contacts': 'CONTACT US',
    'team': 'OUR TEAM',
    'subscribe': 'SUBSCRIBE TO THE NEWSLETTER ',
  }
  ];

  subscriberList = [
    'შემოუერთდით ჩვენ გამომწერთა სიას სიახლეების და აქციების შესახებ ინფორმაციის მისაღებად!',

    'Join our subscriber list for news and promotions!'


  ]

  constructor(private languageService: LanguageService) {

    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  getSubscriptionText() {
    if (this.currentLanguage == 1) {
      return this.subscriberList[0];
    }
    else {
      return this.subscriberList[1];
    }
  }

  getTitles() {

    if (this.currentLanguage == 1) {
      return this.titles = this.titleList[0];
    }
    else {
      return this.titles = this.titleList[1];
    }
  }

}
