import { IAddress } from "../../admin-panel/admin/Interfaces/address.interface";
import { IPhoneNumber } from "../../admin-panel/admin/Interfaces/phoneNumber.interface";

export class Branch {
  id: number;
  orderId: number;
  isMain: boolean;
  title: string;
  address: string;
  phoneNumbers: string[];
  emails: string[];
  workingHours: string[];
  photoUrl: string;
  latitude: number;
  longitude: number;
}
