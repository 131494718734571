import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { reduce, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { IAbout } from '../Interfaces/about-interface';
import { About } from '../models/about.model';
import { Description } from '../models/description.model';
import { Language } from '../models/language.model';
import { ShortDescription } from '../models/shortDescription.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AboutService {
  constructor(
    private httpClient: HttpClient,
  ) { }
  host = 'AboutUs';

  aboutInfo?: About;

  // getCurrentLanguage(): number{
  //   return 1;
  // }

  getAbout(): Observable<About> {
    var url=this.host;
    return this.httpClient.get<IAbout>(`${environment.BaseUrl}/${url}`).pipe(
      map(res => {
        return this.aboutInfo = {
          descriptions: res.descriptions.map(desc => {
            let description = new Description();
            let language = new Language();
            return description = {
              value: desc.value,
              language: language = {
                id: desc.language.id,
                name: desc.language.name,
                shortName: desc.language.shortName
              },
            };
          }),
          shortDescriptions: res.shortDescriptions.map(desc => {
            let shortDescription = new ShortDescription();
            let language = new Language();
            return shortDescription = {
              value: desc.value,
              language: language = {
                id: desc.language.id,
                name: desc.language.name,
                shortName: desc.language.shortName
              },
            };
          }),

          photoUrl: res.photos.length > 0
            ? environment.BaseUrl + '/' + this.host +
            '/Photos?OrderId=' +
            res.photos[0].orderId
            : ''
        };
      })
    );
  }

  UpdateAboutShortDescription({value, languageId}): any{
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/ShortDescriptions`, { value, languageId }, {
      headers
    })
      .subscribe({
        next: data => {
            console.log(data);
        },
        error: error => {
            console.error('There was an error!', error);
        }
    });
  }


  UpdateAboutDescription({value, languageId}): any {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/Descriptions`, { value, languageId }, {
      headers
    })
      .subscribe({
        next: data => {
            console.log(data);
        },
        error: error => {
            console.error('There was an error!', error);
        }
    });
  }


  updatePhoto({ data, name, extention, orderId }) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/Photos`, {
      data,
      name,
      extention,
      orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

}

