import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { InstalmentService } from '../services/instalment.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-instalment-update',
  templateUrl: './instalment-update.component.html',
  styleUrls: ['./instalment-update.component.scss'],
})
export class InstalmentUpdateComponent implements OnInit, OnDestroy {
  instalmentSubscription?: Subscription;
  id: number;
  instalmentId: number;

  constructor(
    private instalmentService: InstalmentService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}
  
  ngOnDestroy(): void {
    this.instalmentSubscription.unsubscribe();
  }

  descForm: FormGroup;
  photoForm: FormGroup;
  ngOnInit(): void {
    this.descForm = this.fb.group({
      descriptions: this.fb.array([]),
    });
    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: 1,
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.fetchInstalmentById(this.id);
  }

  fetchInstalmentById(id: number) {
    this.instalmentSubscription = this.instalmentService
      .getInstalmentById(id)
      .subscribe((data) => {
        data.descriptions.forEach((element) => this.addDescription(element));
        this.photoForm.patchValue({
          ...this,
        });
        this.photoUrl = data.photoUrl;
        this.instalmentId = data.id;
      });
  }

  //description
  get descriptions(): FormArray {
    return <FormArray>this.descForm.get('descriptions');
  }

  addDescription(data): void {
    this.descriptions.push(this.buildDescriptions(data));
  }

  buildDescriptions(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  descriptionId;
  getFormLanguageIdDesc(id: number) {
    this.descriptionId = id;
  }

  editDesc(data) {
    var model = data.descriptions?.find(
      (x) => x.language.id == this.descriptionId
    );

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.instalmentService.updateInstalmentDescription(this.id, updateModel);
  }

  //photo
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  photoUrl;
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.instalmentService.updatePhoto(this.id, this.photoForm);
  }

  removeInstalment(id: number) {
    this.instalmentService.deleteProductById(id);
  }
}
