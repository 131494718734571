import { Component, OnDestroy, OnInit } from '@angular/core';
import { Instalment } from '../shared/models/instalment.model';
import { InstalmentService } from '../services/instalment.service';
import { TitlesService } from '../services/titles.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-instalment-page',
  templateUrl: './instalment-page.component.html',
  styleUrls: ['./instalment-page.component.scss']
})
export class InstalmentPageComponent implements OnInit, OnDestroy {
  titles;
  instalmentsSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private instalmentService: InstalmentService,
    private titlesService: TitlesService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchInstalments();
    this.titles = this.titlesService.getTitles();
  }

  instalments: Instalment[] = [
  ]

  fetchInstalments() {
    this.instalmentsSubscription = this.instalmentService.getInstalments().subscribe((data) => {
      data.forEach((element) => {
        this.instalments.push(
          element
        )
      }
      );
    });
    this.subscriptions.push(this.instalmentsSubscription);
  }
}
