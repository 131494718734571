<section id="display-size">
    <div class='card'>
        <div class='card-body'>
            <div class='row'>
                <p id="p1">
                    Employee List
                </p>
                <button style="margin-bottom: 16px; margin-right: 5%;" (click)="redirectToCreateEmployee()">Create New
                    Employee</button>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th> Full Name</th>
                        <th>
                            Position
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let employee of employeeList;  let i=index'>
                        <th>{{employee.id}}</th>
                        <th> {{employee.fullName}}</th>
                        <th>
                            {{employee.position}}
                        </th>
                        <th>
                            <img style="width: 80px;" [src]='employee.photoUrl'>
                        </th>
                        <th>
                            <button [routerLink]="['/employee-update', employee.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removeEmployee(employee.id)"> DELETE</button>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>