import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColorService } from '../services/color.service';

@Component({
  selector: 'app-color-list',
  templateUrl: './color-list.component.html',
  styleUrls: ['./color-list.component.scss'],
})
export class ColorListComponent implements OnInit, OnDestroy {
  colorSubscription?: Subscription;

  constructor(private colorService: ColorService, private router: Router) {}
  ngOnDestroy(): void {
    this.colorSubscription.unsubscribe();
  }

  colorList = [];
  ngOnInit(): void {
    this.colorSubscription = this.colorService.getColors().subscribe((data) => {
      data.forEach((element) => {
        this.colorList.push(element);
      });
    });
  }

  removeColor(id: number) {
    this.colorService.removeColor(id);
  }

  redirectToCreateColor() {
    this.router.navigateByUrl('color-create');
  }
}
