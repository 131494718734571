import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompositionService } from '../services/composition.service';

@Component({
  selector: 'app-composition-list',
  templateUrl: './composition-list.component.html',
  styleUrls: ['./composition-list.component.scss'],
})
export class CompositionListComponent implements OnInit, OnDestroy {
  compositionSubscription?: Subscription;

  constructor(
    private compositionService: CompositionService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    this.compositionSubscription.unsubscribe();
  }

  compositionList = [];
  ngOnInit(): void {
    this.compositionSubscription = this.compositionService
      .getCompositions()
      .subscribe((data) => {
        data.forEach((element) => {
          this.compositionList.push(element);
        });
      });
  }

  removeComposition(id: number) {
    this.compositionService.removeComposition(id);
  }

  redirectToCreateComposition() {
    this.router.navigateByUrl('composition-create');
  }
}
