<!-- <section class="instalmentPage-section">
  <div class="instalment">
    <div class="title-text">
      <h3>
        <b>{{ titles.instalments }}</b>
      </h3>
      <hr class="title-hr" />
    </div>

    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px">
      <div
        fxFlex.sm="0 1 calc(50%-10px)"
        fxFlex.md="0 1 calc(50%-10px)"
        fxFlex.gt-md="0 1 calc(50%-10px)"
        *ngFor="let instalment of instalments"
      >
      <div class="box">

        <div class="center-vertically">
          <img [src]="instalment.photoUrl" />
        </div>
        <div class="instalment-text">
          <p>{{ instalment.description }}</p>

      </div>
    </div>
    </div>
    </div>
  </div>
</section> -->


<section class="instalment-section">
  <div class="instalment">
    <div class="title-text">
      <h3><b>{{titles.instalments}}</b></h3>
      <hr class="title-hr" />
    </div>
    <div fxLayout.xs="column" fxLayout="row wrap">
      <span fxFlex.sm="0 1 calc(50%)" fxFlex.md="0 1 calc(50%)" fxFlex.gt-md="0 1 calc(50%)"
        *ngFor="let instalment of instalments;  let i=index">
        <div class="box">
          <div class="center-vertically">
            <img [src]="instalment.photoUrl" />
          </div>
          <div class="instalment-text">
            <p>{{ instalment.description }}</p>
          </div>
        </div>
      </span>
    </div>
  </div>
</section>