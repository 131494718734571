<section class="service-section">

  <img class="banner"
[src]="serviceInfo?.photoUrl"
  alt="New Doors - Services Banner"
/>
  <div class="service">
    <div class="title-text">
      <h3><b>{{titles.service}}</b></h3>

      <hr class="title-hr" />
    </div>
    <div class="text">
      <p [innerHtml]='serviceInfo?.description'></p>

      </div>
    </div>

  </section>
