import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUpdateComponent } from './about-update/about-update.component';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill'
import { MatInputModule } from '@angular/material/input';
import { ServiceUpdateComponent } from './service-update/service-update.component';
import { InstalmentUpdateComponent } from './instalment-update/instalment-update.component';
import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductUpdateComponent } from './product-update/product-update.component';
import { ProductListComponent } from './product-list/product-list.component';
import { InstalmenCreateComponent } from './instalment-create/instalment-create.component';
import { InstalmentListComponent } from './instalment-list/instalment-list.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactUpdateComponent } from './contact-update/contact-update.component';
import { EmployeeCreateComponent } from './employee-create/employee-create.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import { ContactsComponent } from './contacts/contacts.component';

import { ColorListComponent } from './color-list/color-list.component';
import { ColorUpdateComponent } from './color-update/color-update.component';
import { ColorCreateComponent } from './color-create/color-create.component';
import { CompositionListComponent } from './composition-list/composition-list.component';
import { CompositionUpdateComponent } from './composition-update/composition-update.component';
import { CompositionCreateComponent } from './composition-create/composition-create.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { HomeHeadersComponent } from './home-headers/home-headers.component';
import { HomeHeaderCreateComponent } from './home-header-create/home-header-create.component';
import { HomeHeadersUpdateComponent } from './home-headers-update/home-headers-update.component';

// import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AboutUpdateComponent,
    ServiceUpdateComponent,
    InstalmentUpdateComponent,
    InstalmenCreateComponent,
    ProductCreateComponent,
    ProductUpdateComponent,
    ProductListComponent,
    InstalmentListComponent,
    ContactCreateComponent,
    ContactListComponent,
    ContactUpdateComponent,
    EmployeeCreateComponent,
    EmployeeListComponent,
    EmployeeUpdateComponent,
    ContactsComponent,

    ColorListComponent,
    ColorUpdateComponent,
    ColorCreateComponent,
    CompositionListComponent,
    CompositionUpdateComponent,
    CompositionCreateComponent,
    HomeHeadersComponent,
    HomeHeaderCreateComponent,
    HomeHeadersUpdateComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    MatInputModule,
    RouterModule,
    MatPaginatorModule,
    RouterModule.forChild([
      { path: 'about-update', component: AboutUpdateComponent },
      { path: 'product-create', component: ProductCreateComponent },
      { path: 'product-update/:id', component: ProductUpdateComponent },
      { path: 'product-list', component: ProductListComponent },
      { path: 'services-update', component: ServiceUpdateComponent },
      { path: 'instalment-create', component: InstalmenCreateComponent },
      { path: 'instalment-update/:id', component: InstalmentUpdateComponent },
      { path: 'instalment-list', component: InstalmentListComponent },
      { path: 'contact-create', component: ContactCreateComponent },
      { path: 'contact-list', component: ContactListComponent },
      { path: 'contact-update/:id', component: ContactUpdateComponent },
      { path: 'employee-create', component: EmployeeCreateComponent },
      { path: 'employee-list', component: EmployeeListComponent },
      { path: 'employee-update/:id', component: EmployeeUpdateComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'color-list', component: ColorListComponent },
      { path: 'color-update/:id', component: ColorUpdateComponent },
      { path: 'color-create', component: ColorCreateComponent },
      { path: 'composition-list', component: CompositionListComponent },
      { path: 'composition-update/:id', component: CompositionUpdateComponent },
      { path: 'composition-create', component: CompositionCreateComponent },
      { path: 'homeHeaders-create', component: HomeHeaderCreateComponent },
      { path: 'homeHeaders', component: HomeHeadersComponent },
      { path: 'homeHeader-update/:id', component: HomeHeadersUpdateComponent },


    ])
  ],
  exports: [
    // AboutUpdateComponent,
    FormsModule,
    QuillModule,
    ReactiveFormsModule,
    MatInputModule,

  ]
})
export class AdminModule { }
