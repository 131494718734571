import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IHomeHeader } from '../admin-panel/admin/Interfaces/home-header.interface';
import { HomeHeader } from '../shared/models/homeHeader.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeHeaderService {
  host = 'HomeHeaders?orderby=orderid enc';
  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService,
  ) { }

  currentLanguage = Number(this.languageService.getCurrentLanguageId());

  getHomeHeaders() {
    return this.httpClient.get<IHomeHeader[]>(`${environment.BaseUrl}/${this.host}`).pipe(
      map((homeHeaders: Array<IHomeHeader>) => {
        return homeHeaders.map((homeHeader) => {
          var homeHeaderInfo = new HomeHeader();
          return homeHeaderInfo = {
            id: homeHeader.id,
            orderId: homeHeader.orderId,
            title: homeHeader.titles.find(x => x.language.id == this.currentLanguage)?.value ?? '',
            description: homeHeader.descriptions.find(x => x.language.id == this.currentLanguage)?.value ?? '',
            photoURL: homeHeader.photos.length > 0 ? environment.BaseUrl + '/HomeHeaders/' + homeHeader.id + '/Photos?OrderId=' + homeHeader.photos[0].orderId : '',
          }
        })
      })
    )
  }
  // getCurrentLanguage() {
  //   return 1;
  // }
}
