import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  host = 'Products';
  constructor(
    private httpClient: HttpClient,
  ) { }
  ;

getCalculatedPrice(id, data){
  const{height, width}=data;
  return this.httpClient.get(`${environment.BaseUrl}/${this.host}/${id}/Prices?Height=${height}&Width=${width}`).pipe(
    map((element) => {
       var calculatedPrice;
      return calculatedPrice = element;
    
    })
  )
}
}
