<section id="Contact-update">
  <p>
    <b>{{branch?.id}}</b>
  </p>

  <div style="max-width: 500px" class="image-upload">
    <div [formGroup]='photoForm'>
      <img style="max-width: 500px" [src]="photoUrl" /> <br>
      <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
    </div>
  </div>

  <form [formGroup]='nameForm' (ngSubmit)="editName(nameForm.value)">
    <div formArrayName="names" *ngFor="let name of names.controls; let i=index">
      <h1> დასახელება - {{name.value.language.name}}</h1>
      <div [formGroupName]=i>
        <input formControlName="value">
         </div>
      <button id="upperbuttons" style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageId(name.value.language.id)">
        რედაქტირება
      </button> <br>
    </div>
  </form>

  <form [formGroup]="addressForm" (ngSubmit)="editAddress(addressForm.value)">
    <div formArrayName="addresses" *ngFor="let address of getAddresses(addressForm); let i=index">
      <div [formGroupName]="0">
        <label> Latitude </label> <input id="input1" formControlName="latitude" /> <br>
        <label> Longitude </label> <input id="LongitudeInput" formControlName="longitude" />
        <div formArrayName="values">
          <div *ngFor="let val of address.get('values').controls; let j=index">
            {{val.value?.language}}
            <div [formGroupName]="j">
              <label>Address</label>
              <input id="input1" formControlName="value" />
            </div>
          </div>
        </div>
      </div>
      <button id="bottombuttons" style="font-size: 16px" mat-stroked-button type="submit">
        რედაქტირება
      </button>
    </div><br>
  </form>

  <form [formGroup]='hoursForm' (ngSubmit)="editHour(hoursForm.value)">
    <div formArrayName="workingHours" *ngFor="let hour of workingHours?.controls; let i=index">
      <h1> სამუშაო საათები - მიმდევრობის ნომერი: {{hour.value.orderId}}</h1>
      <div [formGroupName]=i>
        <input id="input1" formControlName="value">
      </div>
      <button id="bottombuttons" style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormOrderId(hour.value.orderId)">
        რედაქტირება
      </button>
      <button (click)="removeWorkingHour(i)" id="TrashButton"><i class="fa fa-trash fa-2x"></i></button>
    </div>
  </form>

  <form [formGroup]='emailForm' (ngSubmit)="editEmail(emailForm.value)">
    <div formArrayName="emails" *ngFor="let email of emails?.controls; let i=index">
      <h1> იმეილი - მიმდევრობის ნომერი:{{email.value.orderId}}</h1>
      <div [formGroupName]=i>
        <input id="input1" formControlName="value">
      </div>
      <button id="bottombuttons" style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormOrderId(email.value.orderId)">
        რედაქტირება
      </button>
      <button id="TrashButton" (click)="removeEmail(i)"> <i class="fa fa-trash fa-2x"></i> </button>
    </div>
  </form>

  <form [formGroup]='numberForm' (ngSubmit)="editPhoneNum(numberForm.value)">
    <div formArrayName="phoneNumbers" *ngFor="let number of phoneNumbers?.controls; let i=index">
      <h1> ტელეფონის ნომერი - მიმდევრობის ნომერი:{{number.value.orderId}}</h1>
      <div [formGroupName]=i>
        <input id="input1" formControlName="value">
      </div>
      <button id="bottombuttons" style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormOrderId(number.value.orderId)">
        რედაქტირება
      </button>
      <button id="TrashButton" (click)="removeNumber(i)"> <i class="fa fa-trash fa-2x"></i></button>
    </div>
  </form>
</section>