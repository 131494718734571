import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/shared-services/language.service';
import { environment } from 'src/environments/environment';
import { CreateHomeHeader, IHomeHeader } from '../Interfaces/home-header.interface';
import { HomeHeader } from '../models/homeHeader.model';

@Injectable({
  providedIn: 'root'
})
export class HomeHeaderService {
  host = 'HomeHeaders?orderby=orderid enc';
  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService,
  ) { }

  currentLanguage = Number(this.languageService.getCurrentLanguageId());

  getHomeHeaders() {
    return this.httpClient.get<IHomeHeader[]>(`${environment.BaseUrl}/${this.host}`).pipe(
      map((homeHeaders: Array<IHomeHeader>) => {
        return homeHeaders.map((homeHeader) => {
          var homeHeaderInfo = new HomeHeader();
          return homeHeaderInfo = {
            id: homeHeader.id,
            orderId: homeHeader.orderId,
            title: homeHeader.titles.find(x => x.language.id == this.currentLanguage)?.value ?? '',
            description: homeHeader.descriptions.find(x => x.language.id ==  this.currentLanguage)?.value ?? '',
            photoURL: homeHeader.photos.length > 0 ? environment.BaseUrl + '/HomeHeaders/' + homeHeader.id + '/Photos?OrderId=' + homeHeader.photos[0].orderId : '',
          }
        })
      })
    )
  }


  getHomeHeaderById(id: number){
    this.host='HomeHeaders';
    return this.httpClient.get<IHomeHeader>(`${environment.BaseUrl}/${this.host}/${id}`).pipe(
      map((homeHeader) => {
        // return homeHeaders.map((homeHeader) => {
          // var homeHeaderInfo = new HomeHeader();
          var homeHeaderInfo;
          return homeHeaderInfo = {
            id: homeHeader.id,
            orderId: homeHeader.orderId,
            titles: homeHeader.titles?.map((x) =>x),
            descriptions: homeHeader.descriptions?.map((x) =>x),
            photoURL: homeHeader.photos?.length > 0 ? environment.BaseUrl + '/HomeHeaders/' + homeHeader.id + '/Photos?OrderId=' + homeHeader.photos[0].orderId : '',
          }
        // })
      })
    )
  }
  

  createHomeHeaders(data: CreateHomeHeader){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`,
      { orderId : data.orderId, descriptions: data.descriptions, titles: data.titles, photos: data.photos},
   // {data},
      {
      headers
      })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }


  deleteHomeHeaderById(id: number){
    this.host='HomeHeaders';
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, {headers})
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
    //.pipe(
    //  tap(() =>  console.log("error occured!"))
     
      // catchError(error => of(`Bad Promise: ${error}`))
   // );
  }


  updateHomeHeadersTitle(id, data){
    const { value, languageId,} = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url=`${environment.BaseUrl}/${this.host}/${id}/Titles`;
  this.httpClient.put(url,{value, languageId}, {headers})
  .subscribe({
    next: x => {
      console.log(x);
    },
    error: error => {
      console.error('There was an error!', error);
    }
  });

  }


  updateHomeHeadersDescription(id, data){
    const { value, languageId,} = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url=`${environment.BaseUrl}/${this.host}/${id}/Descriptions`;
  this.httpClient.put(url,{value, languageId}, {headers})
  .subscribe({
    next: x => {
      console.log(x);
    },
    error: error => {
      console.error('There was an error!', error);
    }
  });

  }

  updatePhoto(id, dataInfo) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/${id}/Photos`, {
      data: dataInfo.value.data,
      name: dataInfo.value.name,
      extention: dataInfo.value.extention,
      orderId: dataInfo.value.orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }
}
