import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import {Http} from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpClient: HttpClient
    ) { }

time;
  public login(loginData: any): Observable<any> {
    let params = new URLSearchParams();
    params.append('grant_type','password');
    params.append('client_id', 'swaggerui');
    params.append('client_secret', 'secret');
    params.append('username', loginData.user);
    params.append('password', loginData.password);
    let headers =
        new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});
    return this.httpClient.post(`${environment.IdentityBaseUrl}/connect/token`,
        params.toString(), { headers: headers }).pipe(
          map((response: AuthResult) => {
// var date=new Date();
// this.time=date.getTime()/1000;
// console.log(this.time)
          //  var user = {
            var token= response.access_token;
             var lifeTime= response.expires_in;
           // }
            localStorage.setItem('token', token);
            localStorage.setItem('lifetime', String(lifeTime));
//             var tokenDate = new Date(parseInt(localStorage.getItem('lifetime')) * 1000)
// console.log(tokenDate);
            // console.log(localStorage.getItem('token'));
            // console.log(localStorage.getItem('lifetime'));
              console.log(response);
              return response;
          })
        );
  }

logOut(): any{
  localStorage.removeItem('token');
  localStorage.removeItem('lifetime');
}


}


export interface AuthResult {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}
