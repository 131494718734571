
<section class="contact-section">
  <!-- <img class="banner"
[src]="contactBanner"
  alt="New Doors - Contacts Banner"
/> -->



<img class="banner"
[src]="contactBanner"
  alt="New Doors - Services Banner"
/>

  <div class="contact">
    <div class="title-text">
      <h3><b>{{titles.contacts}}</b></h3>

      <hr class="title-hr" />
    </div>


    <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="40px" ngClass.gt-sm="gt-sm-class">
      <div *ngFor="let branch of branchList" fxFlex.gt-sm="0 1 calc(50%-40px)"

        >
        <div class="mainStyle">



          <div id="titleC">
            <h4><b>{{branch.title}}</b> </h4>
          </div>
          <div id="PhotoDiv">
            <img [src]='branch.photoUrl' alt="photo">


          </div>

          <div id="IconsDiv">
            <div id="MarkDiv"> <img src="../../assets/map-marker.png" alt="location">
             <span fxHide.xs=true>{{contactsData.address}}: </span> <p ngHide.xs="true" id="MarkP"> {{branch.address}}</p>
            </div>
            <div id="PhoneDiv" > <img src="../../assets/phone.png" alt="Phone">

           <span fxHide.xs=true> {{contactsData.tel}}: </span>    <p id="PhoneP" *ngFor="let number of branch.phoneNumbers"> {{number}}; </p>
            </div>
            <div id="MailDiv"> <img id="ImgMail" src="../../assets/email.png" alt="Email">
           <span fxHide.xs=true> {{contactsData.email}}:</span>    <p id="MailP" *ngFor="let email of branch.emails"> {{email}}</p>
            </div>
            <div id="ClockDiv"> <img src="../../assets/clock.png" alt="Clock">
             <span fxHide.xs=true>{{contactsData.hours}}: </span> <p id="ClockP"  *ngFor="let workHour of branch.workingHours"> {{workHour}};</p>
            </div>
          </div>
          <!-- <div id="MapDiv"> -->
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11911.163772174286!2d44.7405822!3d41.7250298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x97d2e2fd7fd24df9!2z4YOT4YOU4YOa4YOY4YOh4YOY!5e0!3m2!1ska!2sge!4v1621016755332!5m2!1ska!2sge" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>  -->
<!--           
          </div> -->
          <agm-map [latitude]="branch.latitude" [longitude]="branch.longitude"
          [zoom]="zoom">
            <agm-marker 
                     [latitude]="branch.latitude" [longitude]="branch.longitude"></agm-marker>
          </agm-map>
            <!-- <agm-map [latitude]="lat" [longitude]="lng"></agm-map> -->
          <!-- <div class="my-google-map">
            <google-map></google-map>
          </div> -->


          <!-- <div class="my-google-map">
            <google-map [options]="mapOptions">
               <map-marker [position]="marker.position"></map-marker>
            </google-map>
         </div> -->

        </div>
      </div>
    </div>
  </div>
</section>

<!--
      <div
      style="margin-right: 40px"

      fxFlex.md="0 1 calc(50%-40px)"
      fxFlex.gt-md="0 1 calc(50%-40px)"
           >
           <div style="background-color: lightgrey;
           height: 100px
          ;">


        </div>
    </div>
    <div
    style="margin-right: 20px"

    fxFlex.md="0 1 calc(50%-20px)"
    fxFlex.gt-md="0 1 calc(50%-20px)"
         >
         <div style="background-color: lightgrey;
         height: 100px
        ;"></div>
  </div>
    </div>
  </div>
    </section>
     -->
