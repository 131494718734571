import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompositionService } from '../services/composition.service';

@Component({
  selector: 'app-composition-update',
  templateUrl: './composition-update.component.html',
  styleUrls: ['./composition-update.component.scss'],
})
export class CompositionUpdateComponent implements OnInit, OnDestroy {
  id;
  compositionSubscription?: Subscription;

  nameForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private compositionService: CompositionService
  ) {}
  ngOnDestroy(): void {
    this.compositionSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.nameForm = this.fb.group({
      names: this.fb.array([]),
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getCompositionById(this.id);
  }

  getCompositionById(id: number) {
    this.compositionSubscription = this.compositionService
      .getCompositionById(id)
      .subscribe((data) => {
        data.names.forEach((element) => {
          this.addName(element);
        });
      });
  }

  //name
  get names(): FormArray {
    return <FormArray>this.nameForm.get('names');
  }

  addName(data): void {
    this.names.push(this.buildNames(data));
  }

  buildNames(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  languageId;
  getFormLanguageId(id: number) {
    this.languageId = id;
  }

  editName(data) {
    var model = data.names?.find((x) => x.language.id == this.languageId);

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.compositionService.updateComposition(this.id, updateModel);

    // this.fetchAboutInfo();
  }
}
