import { Component, OnDestroy, OnInit } from '@angular/core';
import { BranchService } from '../services/branch.service';
import { ContactsService } from '../services/contacts.service';
import { Contacts } from '../shared/models/contacts.model';
import { Branch } from '../shared/models/branch.model';
import { LanguageService } from '../services/shared-services/language.service';
import { NavbarService } from '../services/navbar.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  navbar;
 contactSubscription?: Subscription;
  branchSubscription?: Subscription
  subscriptions: Subscription[] = [];
  constructor(
    private contactsService: ContactsService,
    private languageService: LanguageService,
    private branchService: BranchService,
    private navbarService: NavbarService,
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchSocMedia();
    this.fetchMainBranch();
    this.navbar=  this.navbarService.getNavbar();
  }

  public isCollapsed = true;

  socialContact?: Contacts;

  fetchSocMedia() {
    this.contactSubscription = this.contactsService.fetchContact().subscribe((element) => {
      this.socialContact = element;
    })
    this.subscriptions.push(this.contactSubscription)
  }

  mainBranch?: Branch;
  fetchMainBranch() {
    this.branchSubscription = this.branchService.getMainBranch().subscribe(element => {
      if (element) {
        this.mainBranch = {
          ...element
        }
      }
    })
    this.subscriptions.push(this.branchSubscription)

  }

  clickEvent(value) {
    this.languageService.checkLanguage(value);
  }


  date=new Date().getFullYear();
}
