<!-- 
<header>
  <div class="top-header">
    <div id="uppersideleft"> <a (click)="clickEvent('geo')" id="GeoLink"> ქარ </a> <img id="headerdot"
        src="../../assets/asset1.png" alt="dot"> <a (click)="clickEvent('eng')" id="EngLink"> ENG</a>
      <div id="VerticalLine"></div>
    </div>
    <div id="uppersideright">
      <div class="SMDiv" ngClass.md="md-SMDiv" >

        <div id="VerticalLineRight"></div> <a id="Facebook" class="routerlink" href={{socialContact?.facebook}}> <img
            id="FacebookImg" src="../../assets/header-facebook.png" alt="Facebook"> </a> <a id="Youtube"
          class="routerlink" href={{socialContact?.youtube}}> <img id="YoutubeImg" src="../../assets/youtube.png"
            alt="youtube"> </a> <a id="Instagram" class="routerlink" href={{socialContact?.instagram}}> <img
            id="InstagramImg" src="../../assets/header-instagram.png" alt="Instagram"> </a>

        <div id="VerticalLineRight2">

        </div>

      </div>
      <div id="PDiv" fxShow fxHide.lt-lg="true"> 
        <img fxShow fxHide.lt-lg="true" id="Phone" src="../../assets/header-phone.png" alt="Phone">
           <a fxShow fxHide.lt-lg="true" id="PhoneLink" href="#">
             +577 34 54 41
            </a>
      </div>
    </div>
  </div>
  <div ngClass.gt-lg="bg-header">
    <mat-toolbar class="navbar">
      <div> <a [routerLink]="['']"><img src="../../assets/New Doors - Logo.svg" alt="NEW DOORS LOGO" /></a> </div>
      <div fxShow="true" fxHide.lt-lg="true"> <span><a [routerLink]="['']" routerLinkActive="activeLink"
            [routerLinkActiveOptions]="{ exact: true }">{{navbar.main}}</a></span> <span><a
            [routerLink]="['/about-page']" [routerLinkActive]="'activeLink'">{{navbar.about}}</a></span> <span><a
            [routerLink]="['/products-page']" [routerLinkActive]="'activeLink'">{{navbar.prods}}</a></span> <span><a
            [routerLink]="['/services-page']" [routerLinkActive]="'activeLink'">{{navbar.service}}</a></span> <span><a
            [routerLink]="['/instalment-page']" [routerLinkActive]="'activeLink'">{{navbar.instalments}}</a></span>
        <span class="last-item"><a [routerLink]="['/contact-page']"
            [routerLinkActive]="'activeLink'">{{navbar.contacts}}</a></span>
        <button *ngIf='show' mat-raised-button [matMenuTriggerFor]="menu" style='margin-left: 20px; font-size:20px'>Admin</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)='redirectToProducts()'>Products</button>
          <button mat-menu-item (click)='redirectToAbout()'>AboutUS</button>
          <button mat-menu-item (click)='redirectToServices()'>Services</button>
          <button mat-menu-item (click)='redirectToEmployees()'>Employees</button>
          <button mat-menu-item (click)='redirectToContacts()'>Contacts</button>
          <button mat-menu-item (click)='redirectToInstalments()'>Instalments</button>
          <button mat-menu-item (click)='redirectToColors()'>Colors</button>
          <button mat-menu-item (click)='redirectToCompositions()'>Compositions</button>
          <button mat-menu-item (click)='redirectToSocMedia()'>Soc Media Contacts</button>
        </mat-menu>
      </div>
      <div class="button-color" fxShow="true" fxHide.gt-md="true"> 
        <button mat-stroked-button
          (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
          class="main-button"> 
          <span class="navbar-toggler-icon"></span> 
        </button>
         </div>
    </mat-toolbar>
    <div fxShow="true" fxHide.gt-md="true" class="collapsible-navbar">
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ul>
          <li> <a [routerLink]="['']">{{navbar.main}}</a> </li>
          <li> <a [routerLink]="['/about-page']">{{navbar.about}}</a> </li>
          <li> <a [routerLink]="['/products-page']">{{navbar.prods}}</a> </li>
          <li> <a [routerLink]="['/services-page']">{{navbar.service}}</a> </li>
          <li> <a [routerLink]="['/instalment-page']">{{navbar.instalments}}</a> </li>
          <li> <a [routerLink]="['/contact-page']">{{navbar.contacts}}</a> </li>
 
        </ul>
      </div>
    </div>
  </div>
</header> -->








<header>
  <div class="top-header">
    <div id="uppersideleft"> <a (click)="clickEvent('geo')" id="GeoLink"> ქარ </a> <img id="headerdot"
        src="../../assets/asset1.png" alt="dot"> <a (click)="clickEvent('eng')" id="EngLink"> ENG</a>
      <div id="VerticalLine"></div>
    </div>
    <div id="uppersideright">
      <div class="SMDiv" ngClass.md="md-SMDiv" ngClass.sm="sm-SMDiv"  ngClass.xs="xs-SMDiv" >
        <div class="InSMDiv" ngClass.sm="sm-InSMDiv" ngClass.md="md-InSMDiv" ngClass.xs="xs-InSMDiv">
      <div id="VerticalLineRight"></div>
       <a id="ALink" class="routerlink" href={{socialContact?.facebook}}> 
        <img
          id="FacebookImg" src="../../assets/header-facebook.png" alt="Facebook"> </a> 
          <a id="ALink"
        class="routerlink" href={{socialContact?.youtube}}>
         <img id="YoutubeImg" src="../../assets/youtube.png"
          alt="youtube"> </a> 
          <a id="ALink" class="routerlink" href={{socialContact?.instagram}}>
             <img
          id="InstagramImg" src="../../assets/header-instagram.png" alt="Instagram"> </a>
        
      <!-- <img id="Facebook" src="../../assets/header-facebook.png" alt="Facebook"> <img id="Youtube" src="../../assets/youtube.png" alt="youtube"> <img id="Instagram" src="../../assets/header-instagram.png" alt="Instagram"> -->
      <div id="VerticalLineRight">
      </div>
      </div>

      </div>
      <div id="PDiv"  fxShow fxHide.lt-lg="true" > <img fxShow fxHide.lt-lg="true" id="Phone" src="../../assets/header-phone.png" alt="Phone"> <a fxShow fxHide.lt-lg="true" 
        id="PhoneLink" href="#">{{number}}</a>
      </div>
    </div>
  </div>
  <div ngClass.gt-lg="bg-header">
    <mat-toolbar class="navbar">
      <div> <a [routerLink]="['']"><img src="../../assets/New Doors - Logo.svg" alt="NEW DOORS LOGO" /></a> </div>
      <div fxShow="true" fxHide.lt-lg="true"> <span><a [routerLink]="['']" routerLinkActive="activeLink"
            [routerLinkActiveOptions]="{ exact: true }">{{navbar.main}}</a></span> <span><a
            [routerLink]="['/about-page']" [routerLinkActive]="'activeLink'">{{navbar.about}}</a></span> <span><a
            [routerLink]="['/products-page']" [routerLinkActive]="'activeLink'">{{navbar.prods}}</a></span> <span><a
            [routerLink]="['/services-page']" [routerLinkActive]="'activeLink'">{{navbar.service}}</a></span> <span><a
            [routerLink]="['/instalment-page']" [routerLinkActive]="'activeLink'">{{navbar.instalments}}</a></span>
        <span class="last-item"><a [routerLink]="['/contact-page']"
            [routerLinkActive]="'activeLink'">{{navbar.contacts}}</a></span>
            <button *ngIf='show' mat-raised-button [matMenuTriggerFor]="menu" style='margin-left: 20px; font-size:20px'>Admin</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)='redirectToProducts()'>პროდუქტები</button>
              <button mat-menu-item (click)='redirectToAbout()'>ჩვენს შესახებ</button>
              <button mat-menu-item (click)='redirectToServices()'>სერვისები</button>
              <button mat-menu-item (click)='redirectToEmployees()'>თანამშრომლები</button>
              <button mat-menu-item (click)='redirectToContacts()'>ფილიალები</button>
              <button mat-menu-item (click)='redirectToInstalments()'>განვადება</button>
              <button mat-menu-item (click)='redirectToColors()'>ფერები</button>
              <button mat-menu-item (click)='redirectToCompositions()'>შემადგენლობა</button>
              <button mat-menu-item (click)='redirectToSocMedia()'>სოც. მედია</button>
              <button mat-menu-item (click)='redirectToHomeHeaders()'>მთავარი ბანერი</button>
              <button mat-menu-item (click)='logOut()'>გასვლა</button>
            </mat-menu>
      </div>
      <div class="button-color" fxShow="true" fxHide.gt-md="true"> <button mat-stroked-button
          (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
          class="main-button"> <span class="navbar-toggler-icon"></span> </button> </div>
    </mat-toolbar>
    <div fxShow="true" fxHide.gt-md="true" class="collapsible-navbar">
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ul>
          <li> <a [routerLink]="['']">{{navbar.main}}</a> </li>
          <li> <a [routerLink]="['/about-page']">{{navbar.about}}</a> </li>
          <li> <a [routerLink]="['/products-page']">{{navbar.prods}}</a> </li>
          <li> <a [routerLink]="['/services-page']">{{navbar.service}}</a> </li>
          <li> <a [routerLink]="['/instalment-page']">{{navbar.instalments}}</a> </li>
          <li> <a [routerLink]="['/contact-page']">{{navbar.contacts}}</a> </li>
          <!-- <li > <button>ADMIN</button> </li> -->
        </ul>
      </div>
    </div>
  </div>
</header>