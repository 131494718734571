import { ThisReceiver } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeHeaderService } from '../services/home-header.service';

@Component({
  selector: 'app-home-headers',
  templateUrl: './home-headers.component.html',
  styleUrls: ['./home-headers.component.scss']
})
export class HomeHeadersComponent implements OnInit, OnDestroy {
  homeHeadersSubscription?: Subscription;
  form;
  constructor(
    private fb: FormBuilder,
    private homeHeaderService: HomeHeaderService,
    private router: Router
  ) { }
  ngOnDestroy(): void {
    this.homeHeadersSubscription.unsubscribe();
  }

  homeHeaderList=[];
  ngOnInit(): void {
   this.homeHeadersSubscription = this.homeHeaderService.getHomeHeaders().subscribe(data => {
      data.forEach(element => {
        this.homeHeaderList.push(element);
      })
      })
  }

  redirectToCreateHomeHeaders(){
    this.router.navigateByUrl('homeHeaders-create');
  }
  removeHomeHeader(id: number){
    this.homeHeaderService.deleteHomeHeaderById(id);

  }
  onSubmit(data){
    this.homeHeaderService.getHomeHeaders();
  }
}
