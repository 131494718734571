import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IService } from '../admin-panel/admin/Interfaces/service.interface';
import { Service } from '../shared/models/service.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  host = 'Services';
  currentLanguage;

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());

  }

  // getService() {
  //   return this.httpClient.get<IServices>(`${this.host}`);
  // }


  serviceInfo?: Service;
  getService() {
    return this.httpClient.get<IService>(`${environment.BaseUrl}/${this.host}?orderby=orderid enc`).pipe(
      map(res => {
        return this.serviceInfo = {
          description: res.descriptions.find(x => x.language.id == this.currentLanguage)?.value ?? '',
          shortDescription: res.shortDescriptions.find(x => x.language.id = this.currentLanguage)?.value ?? '',
          photoUrl: res.photos.length > 0
            ? environment.BaseUrl + '/' + this.host + '/' +
            'Photos?OrderId=' +
            res.photos[0].orderId
            : '',
        }
      }))
  }
}
