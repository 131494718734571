<section class='home'>

  <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item *ngFor='let homeHeader of homeHeaders'>
      <div class="view w-100">
        <img style="height:550px; object-fit: cover;" class="d-block w-100" [src]="homeHeader.photoURL"
          alt="First slide">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">
          {{homeHeader.title}} </h3>
        <p>{{homeHeader.description}}</p>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>









  <section class="about-section">
    <div class="about">
      <div class="title-text">
        <h3><b>{{titles.about}}</b></h3>

        <hr class="title-hr" />
      </div>
      <div class="text">
        <p [innerHtml]='aboutText'></p>
        <div class="button">
          <button ngClass.gt-md="AboutButton" ngClass.lt-lg="lt-xl-buttonWidth" style="font-size: 16px"
            mat-stroked-button [routerLink]="['/about-page']">
            More About Us
          </button>
        </div>
      </div>
    </div>
  </section>

  <div class="clear"></div>

  <!-- <section class="product-section">
  <div class="product">
    <div class="title-text">
      <h3><b>{{titles.prods}}</b></h3>
      <hr class="title-hr"/>
    </div>

    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="40px" style="margin-left: 40px;" >
      <span class="prod" fxFlex.sm="0 1 calc(50%-40px)" fxFlex.md="0 1 calc(33.3%-40px)" fxFlex.gt-md="0 1 calc(33.3% -40px)" *ngFor="let photo of photos;  let i=index">
        <img 
 
          [src]='photo.photoUrl'>

        </span>
    </div>

  </div>
</section> -->

  <!-- <div class="clear"></div> -->

  <section class='product-section'>
    <div fxLayout="row wrap" fxLayoutGap="20px" style="margin-left:20px">
      <div class="prod" fxFlex.xs="0 1 calc(50%-20px)" fxFlex.sm="0 1 calc(50%-20px)" fxFlex.md="0 1 calc(33.3%-20px)"
        fxFlex.gt-md="0 1 calc(33.3%-20px)" *ngFor="let product of products;  let i=index">
        <a [routerLink]="['/products-page', product.id]">
          <div>
            <div ngClass.gt-md="gt-md-position" ngClass.lt-lg="lt-lg-position">
              <img [src]='product?.photoUrl' ngClass.gt-md="gt-md-width" ngClass.lt-lg="lt-lg-width">
              <div *ngIf="product.showSalePercentage" ngClass.gt-md="gt-md-relative" ngClass.lt-lg="lt-lg-relative">
                -{{product.salePercentage}}%
              </div>
            </div>
          </div>
        </a>

        <div class="door-text">
          <p><b>{{product.name}}</b></p>
          <p>
            <span [ngClass]="product.saleValue || product.salePercentage ? 'strikethrough' : 'null'"
              style="margin-right: 15%;"> {{product.originalValue | currency:'GEL':'symbol-narrow'}}</span>
            <span *ngIf="product.saleValue || product.salePercentage" style="color: red;">
              {{product.saledValue | currency:'GEL':'symbol-narrow' }}
            </span>
          </p>
        </div>
      </div>


    </div>
    <div class="button">
    <button class="more" ngClass.gt-md="ProductButton" ngClass.lt-lg="lt-xl-buttonWidth" style="font-size: 16px"
    mat-stroked-button [routerLink]="['/products-page']">Show More</button>
  </div>
  </section>
  <section class="instalment-section">
    <div class="instalment">
      <div class="title-text">
        <h3><b>{{titles.instalments}}</b></h3>
        <hr class="title-hr" />
      </div>

      <div fxLayout.xs="column" fxLayout="row wrap">
        <span fxFlex.sm="0 1 calc(50%)" fxFlex.md="0 1 calc(50%)" fxFlex.gt-md="0 1 calc(50%)"
          *ngFor="let instalment of instalments;  let i=index">
          <!-- <img [src]='logo'> -->


          <div class="box">

            <div class="center-vertically">
              <img [src]="instalment.photoUrl" />
            </div>
            <div class="instalment-text">
              <p>{{ instalment.description }}</p>
    
          </div>
        </div>
        </span>
      </div>

    </div>
  </section>


  <section class="team-section">
    <div class="team">
      <div class="title-text">
        <h3><b>{{titles.team}}</b></h3>
        <hr class="title-hr" />
      </div>

      <div fxLayout.xs="column" fxLayout="row wrap">
        <span fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33.3%)" fxFlex.gt-md="0 1 calc(33.3%)"
          *ngFor="let member of members; let i=index">
          <img [src]='member.photoUrl'>
          <div class="team-text">
            <h5>{{member.fullName}}</h5>
            <p>{{member.position}}</p>
          </div>
        </span>
      </div>

    </div>
  </section>

  <!-- <div class="clear"></div> -->

  <section class="subscriber-section">
    <div class="subscriber">
      <div class="title-text">
        <h3><b>{{titles.subscribe}}</b></h3>
        <hr class="title-hr" />
      </div>
      <img src='../../assets/email-open.png'>
      <div class="text" ngClass.sm="sm-text" ngClass.xs="xs-text">
        {{subscriberText}}
      </div>

      <div id="InputDiv">
        <form id="Input">

          <!-- <label id="mail"> </label> -->
          <input type="text" id="mail" name="mail" [(ngModel)]="mail" placeholder="თქვენი ელ - ფოსტა">
          <button type="submit" (click)="clickme()">გამოწერა</button>
        </form>
      </div>
    </div>
  </section>
  <!-- <div class="clear"></div> -->

  <!-- <section class="map">
  <div class="map"> -->
  <agm-map [latitude]="mainBranch?.latitude" [longitude]="mainBranch?.longitude" [zoom]="zoom">
    <agm-marker [latitude]="mainBranch?.latitude" [longitude]="mainBranch?.longitude"></agm-marker>
  </agm-map>
  <!-- </div>
</section> -->
</section>