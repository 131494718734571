import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CalculatorService } from '../services/calculator.service';
import { ProductDetailsDataService } from '../services/product-details-data.service';
import { ProductService } from '../services/product.service';
import { ProductDetails } from '../shared/models/productDetails.model';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss']
})

export class ProductDetailsPageComponent implements OnInit, OnDestroy {
  productDetailsData;
  id;
  form: FormGroup;
  productSubscription?: Subscription;
  calculatorSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private productDetailsDataService: ProductDetailsDataService,
    private fb: FormBuilder,
    private calculatorService: CalculatorService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.fetchProductByID(this.id);
    this.productDetailsData = this.productDetailsDataService.getProdDetailsData();
    this.form = this.fb.group({
      height: null,
      width: null
    })
  }

  product: ProductDetails;

  fetchProductByID(id: number) {
    this.productSubscription = this.productService.getProductByID(id).subscribe((element) => {
      this.product = element
    }
    )
    this.subscriptions.push(this.productSubscription);
  }

  show = false;
  calculatedPrice;
  calculatePrice(id, data) {
    this.calculatorSubscription = this.calculatorService.getCalculatedPrice(this.id, data).subscribe(element => {
      this.calculatedPrice = element;
      this.show = true;
    })
    this.subscriptions.push(this.calculatorSubscription);
  }

}
