<section id="display-size">
    <div class='card'>

        <div class='card-header'>
            <p id="p1">
                Color List
            </p>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-md-2'>
                    <button style="margin-bottom: 20px" (click)="redirectToCreateColor()"> Create New Color</button>
                </div>
            </div>

            <div class='row'>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th> Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let color of colorList;  let i=index'>
                        <th>{{color.id}}</th>
                        <th> {{color.name}}</th>
                        <th>
                            <button [routerLink]="['/color-update', color.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removeColor(color.id)"> DELETE</button>
                        </th>

                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</section>