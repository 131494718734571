import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColorService } from '../services/color.service';
import { CompositionService } from '../services/composition.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  colorSubscription?: Subscription;
  compositionSubscription?: Subscription;
 
  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private colorService: ColorService,
    private compositionService: CompositionService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      names: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
      descriptions: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
      colors: this.fb.array([
        this.fb.group({
          colorId: 0,
        }),
      ]),
      compositions: this.fb.array([
        this.fb.group({
          compositionId: 0,
        }),
      ]),
      productType: 1,
      calculator: 1,
      dimentions: this.fb.group({
        width: 0,
        height: 0,
      }),

      prices: this.fb.group({
        originalValue: 0,
        salePercentage: 0,
        showSalePercentage: true,
        saleValue: 0,
        saledValue: 0,
      }),
      photos: this.fb.array([
        this.fb.group({
          data: 'string',
          name: 'string',
          extention: 'png',
          orderId: 1,
        }),
      ]),
    });

    this.getCompositionList();

    this.getColorList();
    
  }

  colorList = [];
  // subscriptions?: Subscription[];
  subscriptions: Subscription[] = [];
  getColorList() {
    this.colorSubscription = this.colorService.getColors().subscribe((data) => {
      data.forEach((element) => {
        console.log(element);
        this.colorList?.push(element);
      });
    });
    this.subscriptions.push(this.colorSubscription);
  }

  compositionList = [];

  getCompositionList() {
    // console.log('amex')
    this.compositionSubscription = this.compositionService.getCompositions().subscribe((data) => {
        data.forEach((element) => {
          console.log(element);
          this.compositionList?.push(element);
        });
      });
    this.subscriptions.push(this.compositionSubscription);
  }

  get colors(): FormArray {
    return <FormArray>this.form.get('colors');
  }
  addColor(): void {
    this.colors.push(this.buildColors());
  }

  removeColor(id: number) {
    this.colors.removeAt(id);
  }

  buildColors(): FormGroup {
    return this.fb.group({
      colorId: 0,
    });
  }
  get compositions(): FormArray {
    return <FormArray>this.form.get('compositions');
  }


  addComposition(): void {
    this.compositions.push(this.buildCompositions());
  }
  removeComposition(id: number) {
    this.compositions.removeAt(id);
  }

  buildCompositions(): FormGroup {
    return this.fb.group({
      // value: false,
      compositionId: 0,
    });
  }

  
  //photos

  get photos(): FormArray {
    return this.form.get('photos') as FormArray;
  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  photoUrl;
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.photos.patchValue([{ data: base64File }]);
    console.log(base64File);
  }

  onSubmit(data) {
    this.productService.createProduct(data);
    this.form.reset();
  }
}
