<section id="display-size">
    <div class='card'> 
        <div class='card-header'>
            <p id="p1">
                Home-Headers   List
</p>
</div>
<div class='card-body'>
<div class='row'>
<div class='col-md-2'>
<button id="buttonC" style="margin-bottom: 20px" (click)="redirectToCreateHomeHeaders()">Create New Home-Header</button>
</div>
</div>

<div class='row'>
</div>
<table class="table">
<thead>
    <tr>
     
        <th>ID</th>
     
        <th>
    Title
        </th>
           </tr>
</thead>
<tbody>
<tr *ngFor='let homeHeader of homeHeaderList;  let i=index'>
<th>{{homeHeader.id}}</th>
<th>   {{homeHeader.title}}</th>

<th>
   <img style="width: 80px;" [src]='homeHeader.photoURL'>
</th>
<th>
    <button [routerLink]="['/homeHeader-update', homeHeader.id]"> EDIT</button>
</th>
<th>
    <button (click)="removeHomeHeader(homeHeader.id)"> DELETE</button>
</th>

</tr>
</tbody>
</table>

</div>
</div>
</section>

