<section id="Instalment-create">
    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">
        <div class="image-upload">
            <img [src]="photoUrl" />
            <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)"
                formControlName="photoUrl" />
        </div>

        <p>თანმიმდევრობა</p>
        <input id="input1" formControlName="orderId">

        <div formArrayName="descriptions">
            <p>აღწერა </p>
            <div [formGroupName]=0>
                <quill-editor formControlName="value"></quill-editor>

            </div>
            <p>description</p>
            <div [formGroupName]=1>
                <quill-editor formControlName="value"></quill-editor>

            </div>
        </div>

        <button type="submit" id="SButton">
            Submit
        </button>
    </form>

</section>

