<section id="display-size">
    <div class='card'>
        <div class='card-header'>
            <p id="p1">
                Composition List
            </p>
        </div>
        <div class='card-body'>
            <div class='row'>
                               <div class='col-md-2'>
                    <button style="margin-bottom: 20px" id="buttonC" (click)="redirectToCreateComposition()">Create New Composition</button>
                </div>
            </div>
         
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th> Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let composition of compositionList;  let i=index'>
                        <th>{{composition.id}}</th>
                        <th> {{composition.name}}</th>
                        <th>
                            <button [routerLink]="['/composition-update', composition.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removeComposition(composition.id)"> DELETE</button>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>