import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { IProduct } from '../Interfaces/productDetails.interface';
import { ProductList } from '../models/productList.model';
import { LanguageService } from '../../../services/shared-services/language.service';
import { ProductDetails } from '../models/productDetails.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  host = 'Products';
  currentLanguage

  constructor(
    private httpClient: HttpClient,

    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  createProduct(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`, {
      photos: data.photos,
      data: data.title,
      calculator: data.calculator,
      dimentions: data.dimentions,
      prices: data.prices,
      productType: data.productType,
      names: data.names,
      descriptions: data.descriptions,
      colors: data.colors,
      compositions: data.compositions,

    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  updateProductById(id: number, data) {
    const { productType, calculator, dimentions, prices, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}`;
    this.httpClient.put(url, {
      productType, calculator, dimentions, prices,
    }, {
      headers
    })
      // .pipe(
      //   tap(() => console.log('cvb')),
      //   map(() => data),
      //   // catchError(this.handleError)
      // )
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  deleteProductById(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
    //.pipe(
    //  tap(() =>  console.log("error occured!"))

    // catchError(error => of(`Bad Promise: ${error}`))
    // );
  }


  getProductList(pageNumber?: number) {
this.host = 'Products';
    let pageQuery = '';
    if (pageNumber) {
             pageQuery = `?PageNumber=${pageNumber}`;
     }
this.host+= pageQuery;

let headers = new HttpHeaders({ 'Access-Control-Expose-Headers': 'X-Pagination' });


    return this.httpClient.get<IProduct[]>(`${environment.BaseUrl}/${this.host}`, { headers: headers, observe: 'response', responseType: 'json' }).pipe(
  
      // products: Array<IProduct>
      map((resp) => {
        const mypagination = resp.headers.get('X-Pagination');
        console.log(mypagination);
        var jsonMypagination = JSON.parse(mypagination);
        var totalCount = jsonMypagination.totalCount;
        console.log(totalCount);


      return resp.body.map((product) => {
        var productInfo = new ProductList();
        return productInfo = {
          totalCount: totalCount,
          id: product.id,
          originalValue: product.prices.originalValue,
          photoUrl: product.photos.length > 0
            ? environment.BaseUrl + '/' + 'products' + '/'+
            product.id +
            '/Photos?OrderId=' + product.photos[0].orderId
            : '',
          name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',

          // names: product.names.map((x) => x.value ?? ''),
        }
      })
    }
    ));

  }




  getProductByID(id: number)
  //: Observable<ProductDetails> 
  {
    this.host = 'Products';
    return this.httpClient.get<IProduct>(`${environment.BaseUrl}/${this.host}/${id}`).pipe(
      map(
        (product
          // : IProduct
        ) => {
          //  return products.map((productDetails) => {
          // var productInfo = new ProductDetails();
          var productInfo;
          return productInfo = {
            id: product.id,
            // names: product.names.map((x)=> x.value) ?? '',
            names: product.names.map(x => x),
            descriptions: product.descriptions.map(x => x),
            //name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            //  name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            // description: product.descriptions.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            //colors: product.colors.map(x => x.names.find((x) => x.language.id == this.currentLanguage)?.value ?? ''),
            colors: product.colors.map(x => x),
            // compositions: product.compositions.map(x => x.names.find(x => x.language.id == this.currentLanguage)?.value ?? ''),
            compositions: product.compositions.map(x => x),
            productType: product.productType.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            calculator: product.calcualtor?.id,
            width: product.dimentions.width,
            height: product.dimentions.height,
            originalValue: product.prices.originalValue,
            salePercentage: product.prices.salePercentage,
            showSalePercentage: product.prices.showSalePercentage,
            saleValue: product.prices.saleValue,
            saledValue: product.prices.saledValue,
            photoUrl: product.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/'+ + product.id + '/Photos?OrderId=' + product.photos[0].orderId : '',
          }
        }
      )
    );
  }




  updateProductName(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Names`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }





  updateProductDescription(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Descriptions`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  updateProductColor(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Descriptions`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  updatePhoto(id, dataInfo) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/${id}/Photos`, {
      data: dataInfo.value.data,
      name: dataInfo.value.name,
      extention: dataInfo.value.extention,
      orderId: dataInfo.value.orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }




  createProductComposition(id, data) {
    // const{compositionId=data.compositionId}= data
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
   this.httpClient.post(`${environment.BaseUrl}/${this.host}/${id}/Compositions`,  data, {
     headers
   })
     .subscribe({
       next: x => {
         console.log(x);
       },
       error: error => {
         console.error('There was an error!', error);
       }
     });
 }


 createProductColor(id, data) {
  // const{compositionId=data.compositionId}= data
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
 this.httpClient.post(`${environment.BaseUrl}/${this.host}/${id}/Colors`,  data, {
   headers
 })
   .subscribe({
     next: x => {
       console.log(x);
     },
     error: error => {
       console.error('There was an error!', error);
     }
   });
}

removeDataColor(productId, colorId){
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${productId}/Colors/${colorId}`, {headers})
  .subscribe({
    next: x => {
      console.log(x);
    },
    error: error => {
      console.error('There was an error!', error);
    }
  });
}

removeDataComposition(productId, compositionId){
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${productId}/Compositions/${compositionId}`, {headers})
  .subscribe({
    next: x => {
      console.log(x);
    },
    error: error => {
      console.error('There was an error!', error);
    }
  });
}
}
