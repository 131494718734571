import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-employee-update',
  templateUrl: './employee-update.component.html',
  styleUrls: ['./employee-update.component.scss'],
})
export class EmployeeUpdateComponent implements OnInit, OnDestroy {
  employeeSubscription?: Subscription;
  id;
  photoUrl;
  employee;
  orderForm;
  fullNameForm: FormGroup;
  positionForm: FormGroup;
  photoForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private employeeService: EmployeeService
  ) {}
  ngOnDestroy(): void {
    this.employeeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.fullNameForm = this.fb.group({
      fullNames: this.fb.array([]),
    });
    this.positionForm = this.fb.group({
      positions: this.fb.array([]),
    });
    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: null,
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getEmployeeById(this.id);
  }

  getEmployeeById(id: number) {
    this.employeeSubscription = this.employeeService
      .getEmployeeById(id)
      .subscribe((data) => {
        this.employee = data;

        data.fullNames.forEach((element) => {
          this.addFullName(element);
        });

        data.positions.forEach((element) => {
          this.addPositions(element);
        });

        this.photoForm.patchValue({
          orderId: data.photoOrderId,
        });

        this.photoUrl = data.photoUrl;
      });
  }

  //languageID
  languageId;
  getFormLanguageId(id: number) {
    this.languageId = id;
  }

  //fullName

  get fullNames(): FormArray {
    return <FormArray>this.fullNameForm.get('fullNames');
  }

  addFullName(data): void {
    this.fullNames.push(this.buildFullName(data));
  }

  buildFullName(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  editFullName(data) {
    var model = data.fullNames?.find((x) => x.language?.id == this.languageId);

    var values = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.employeeService.updateEmployeeFullName(this.id, values);
  }

  //position

  get positions(): FormArray {
    return <FormArray>this.positionForm.get('positions');
  }

  addPositions(data): void {
    this.positions.push(this.buildPositions(data));
  }

  buildPositions(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  editPosition(data) {
    var model = data.positions?.find((x) => x.language?.id == this.languageId);

    var values = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.employeeService.updateEmployeePosition(this.id, values);
  }

  //photo
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.employeeService.updatePhoto(this.id, this.photoForm.value);
  }
}
