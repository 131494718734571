import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeHeaderService } from '../services/home-header.service';

@Component({
  selector: 'app-home-headers-update',
  templateUrl: './home-headers-update.component.html',
  styleUrls: ['./home-headers-update.component.scss'],
})
export class HomeHeadersUpdateComponent implements OnInit, OnDestroy {
  homeHeadersSubscription?: Subscription;
  id;
  titleForm;
  descForm;
  photoForm;
  photoUrl;
  homeHeaderId;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private homeHeaderService: HomeHeaderService
  ) {}
  ngOnDestroy(): void {
    this.homeHeadersSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.titleForm = this.fb.group({
      titles: this.fb.array([]),
    });

    this.descForm = this.fb.group({
      descriptions: this.fb.array([]),
    });
    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: 1,
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.fetchHomeHeaderById(this.id);
  }

  fetchHomeHeaderById(id: number) {
    this.homeHeadersSubscription = this.homeHeaderService
      .getHomeHeaderById(id)
      .subscribe((data) => {
        data.titles?.forEach((element) => this.addTitle(element));
        data.descriptions?.forEach((element) => this.addDescription(element));
        this.photoForm.patchValue({
          ...this,
        });
        this.photoUrl = data.photoURL;
        this.homeHeaderId = data.id;
      });
  }

  //titiles

  get titles(): FormArray {
    return <FormArray>this.titleForm.get('titles');
  }

  addTitle(data): void {
    this.titles.push(this.buildTitles(data));
  }

  buildTitles(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  titleId;
  getFormLanguageIdTitle(id: number) {
    this.titleId = id;
  }

  editTitle(data) {
    var model = data.titles?.find((x) => x.language.id == this.descriptionId);

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.homeHeaderService.updateHomeHeadersTitle(this.id, updateModel);
  }

  //description
  get descriptions(): FormArray {
    return <FormArray>this.descForm.get('descriptions');
  }

  addDescription(data): void {
    this.descriptions.push(this.buildDescriptions(data));
  }

  buildDescriptions(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  descriptionId;
  getFormLanguageIdDesc(id: number) {
    this.descriptionId = id;
  }

  editDesc(data) {
    var model = data.descriptions?.find(
      (x) => x.language.id == this.descriptionId
    );

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.homeHeaderService.updateHomeHeadersDescription(this.id, updateModel);
  }

  //photo
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.homeHeaderService.updatePhoto(this.id, this.photoForm);
  }
}
