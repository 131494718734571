import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IContacts } from '../admin-panel/admin/Interfaces/contacts.interface';
import { Contacts } from '../shared/models/contacts.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
host='Contacts';
  constructor(
    private httpClient:HttpClient,
  ) { }


  contactsInfo: Contacts;
fetchContact(){
  return this.httpClient.get<IContacts>(`${environment.BaseUrl}/${this.host}`).pipe(
    map((contacts: IContacts) => {
      return this.contactsInfo={
        facebookUserId: contacts.facebookUserId,
  facebook: contacts.facebook,
  instagram: contacts.instagram,
  youtube: contacts.youtube,
  photoUrl: contacts.photos.length > 0 ? environment.BaseUrl + '/' + this.host +'/Photos?OrderId='+ contacts.photos[0].orderId: '',

}
    }
    )
  )
  }




// getCurrentLanguage() {
//   return 1;
// }
}




