import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { AboutPageComponent } from './about-page/about-page.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { InstalmentPageComponent } from './instalment-page/instalment-page.component';
import { ProductDetailsPageComponent } from './product-details-page/product-details-page.component';

import { HttpClientModule } from '@angular/common/http';
import { ProductsPageComponent } from './products-page/products-page.component';

import { MatFormFieldModule } from '@angular/material/form-field';
//import { NgImageSliderModule } from 'ng-image-slider';


//admin menu
import { MatMenuModule } from '@angular/material/menu';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// For MDB Angular Free
import { CarouselModule, WavesModule } from 'angular-bootstrap-md'
import { RedirectGuard } from './redirect.guard';
import { LoginPageComponent } from './login-page/login-page.component';

//fb
import { FacebookModule } from 'ngx-facebook';

import { AdminModule } from './admin-panel/admin/admin.module';


import { OverlayModule } from '@angular/cdk/overlay';


//scroll
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

//map
//import { GoogleMapsModule } from '@angular/google-maps'

//onScroll
import { AgmCoreModule } from '@agm/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { MarkerManager } from '@agm/core';
//import { Loader } from '@googlemaps/js-api-loader';

//pag
import { MatPaginatorModule } from '@angular/material/paginator';

import { APP_BASE_HREF } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    ServicePageComponent,
    HeaderComponent,
    FooterComponent,
    ContactPageComponent,
    InstalmentPageComponent,
    ProductDetailsPageComponent,
    ProductsPageComponent,
    LoginPageComponent,
    //AboutInputComponent,

  ],
  imports: [
    AdminModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FlexLayoutModule.withConfig({ ssrObserveBreakpoints: ['xs', 'lt-md'] }),
    MatToolbarModule,

    NgbModule,
    HttpClientModule,

    MatCheckboxModule,
    MatFormFieldModule,

    BrowserAnimationsModule,
    MatButtonModule,
    // NgImageSliderModule,
    CarouselModule,
    WavesModule,
    OverlayModule,
    //GoogleMapsModule,
    //  MarkerManager,
    // Loader,
    InfiniteScrollModule,
    MatPaginatorModule,
    MatMenuModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAHHaBcMEbqfzoBD3wdyEFLPhucJVUzzaE'
    }),
    FacebookModule.forRoot(),
    RouterModule.forRoot([{
      path: '',
      redirectTo: 'main-page',
      pathMatch: 'full',
    },
    { path: '', component: HomePageComponent },
    { path: 'about-page', component: AboutPageComponent },
    { path: 'products-page', component: ProductsPageComponent },
    { path: 'products-page/:id', component: ProductDetailsPageComponent },
    { path: 'services-page', component: ServicePageComponent },
    { path: 'instalment-page', component: InstalmentPageComponent },
    { path: 'login-page', component: LoginPageComponent },
    { path: 'contact-page', component: ContactPageComponent },
    {
      path: 'admin',
      canActivate: [],
      loadChildren: () => import('./admin-panel/admin/admin.module').then(m => m.AdminModule),
    },
    ], {
      initialNavigation: 'enabled'
    }),
  ],
  providers: [RedirectGuard,
    { provide: APP_BASE_HREF, useValue: '/' }
    // , GoogleMapsAPIWrapper,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);