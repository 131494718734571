<section id="display-size">
  <p>
    <b>{{employee?.id}}</b>
  </p>
  <div class="image-upload">
    <div [formGroup]='photoForm'>
      <img [src]="photoUrl" alt="employee pic" /> <br>
      <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
    </div>
  </div>

  <form [formGroup]="fullNameForm" (ngSubmit)="editFullName(fullNameForm.value)">
    <div formArrayName="fullNames" *ngFor="let fullName of fullNames.controls; let i=index">
      <p> Position - {{fullName?.value?.language?.name}} </p>
      <div [formGroupName]="i">
        <input id="input1" formControlName="value" />
      </div>
      <button style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageId(fullName.value.language?.id)">
        რედაქტირება
      </button>
    </div><br>
  </form>


  <form [formGroup]="positionForm" (ngSubmit)="editPosition(positionForm.value)">
    <div formArrayName="positions" *ngFor="let position of positions.controls; let i=index">
      <p> Position - {{position?.value?.language?.name}}</p>
      <div [formGroupName]="i">
        <input id="input1" formControlName="value" />
      </div>
      <button style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageId(position.value.language?.id)">
        რედაქტირება
      </button>
    </div><br>
  </form>

</section>