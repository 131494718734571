import { CreateLanguagedObject, IDescription } from "./description.interface";
import { CreatePhoto, IPhoto } from "./photo.interface";
import { ITitle } from "./title.interface";


export interface IInstalment {
  id: number;
  orderId: number;
  titles: ITitle[];
  descriptions: IDescription[];
  photos: IPhoto[];
}

export class CreateInstalment {
  orderId: number;
  descriptions: CreateLanguagedObject[];
  photos: CreatePhoto[];
  titles: CreateLanguagedObject[];
}
