<section id="Product-update">
  <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

    <p>
      <b>{{product?.id}} </b>
    </p>



    <div class="image-upload">
      <div [formGroup]='photoForm'>
        <img [src]="photoUrl" /> <br>
        <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
      </div>
    </div>



    <form [formGroup]='nameForm' (ngSubmit)="editName(nameForm.value)">
      <div formArrayName="names" *ngFor="let name of names.controls; let i=index">
        <p> დასახელება - {{name.value.language.name}}</p>
        <div [formGroupName]=i>
          <input id="input1" formControlName="value">

        </div>
        <button style="font-size: 16px" mat-stroked-button type="submit"
          (click)="getFormLanguageIdName(name.value.language.id)">
          რედაქტირება
        </button>
      </div>
    </form>

    <form [formGroup]='descForm' (ngSubmit)="editDesc(descForm.value)">
      <div formArrayName="descriptions" *ngFor="let description of descriptions?.controls; let i=index">
        <p> აღწერა - {{description.value.language.name}}</p>
        <div [formGroupName]=i>
          <quill-editor formControlName="value">
          </quill-editor>
        </div>
        <button id="RightButton" style="font-size: 16px" mat-stroked-button type="submit"
          (click)="getFormLanguageIdDesc(description.value.language.id)">
          რედაქტირება
        </button>
      </div>
    </form>

    <p> <b> Colors: </b> {{product?.colors[0]?.id}} </p> <span>
      <button id="PlusButton" (click)="addColor()"><i class="fa fa-plus" aria-hidden="true"></i></button> </span>
    <div *ngFor='let prodColors of product?.colors;  let i=index'>
      <span *ngFor='let color of prodColors.names'>
        {{color.value}}

      </span>

      <span (click)="removeDataColor(product.id, prodColors.id)"> <button id="TrashButton"> <i
            class="fa fa-trash fa-2x"></i> </button></span>
    </div>


    <form [formGroup]='colorForm' (ngSubmit)="editColor(colorForm.value)">
      <div formArrayName="colors" *ngFor="let color of colors?.controls; let i=index">
        <div [formGroupName]=i>
          <select class="custom-select" id="ColorDiv" formControlName="colorId">
            <option value="" disabled>Choose color</option>
            <option *ngFor="let color of colorList" [ngValue]="color.id">{{color.name}}</option>

          </select>
          <span> <button (click)="removeColor(i)" id="TrashButton"><i class="fa fa-trash fa-lg"></i></button> </span>
          <br>

          <button id="SelectButton" style="font-size: 16px" mat-stroked-button type="submit" (click)="getIndex(i)">
            დამატება
          </button>
        </div>
      </div>
    </form>

    <p> <b> Composition: </b> </p> <span><button id="PlusButton" (click)="addComposition()"><i class="fa fa-plus"
          aria-hidden="true"></i></button></span>
    <div *ngFor='let prodCompositions of product?.compositions;  let i=index'>
      <span *ngFor='let composition of prodCompositions.names'>
        {{composition.value}}

      </span>
      <span (click)="removeDataComposition(product.id, prodCompositions.id)"> <button id="TrashButton"> <i
            class="fa fa-trash fa-2x"></i> </button></span>
    </div>

    <form [formGroup]='compositionForm' (ngSubmit)="editComposition(compositionForm.value)">
      <div formArrayName="compositions" *ngFor="let composition of compositions?.controls; let i=index">

        <div [formGroupName]=i>

          <select class="custom-select" id="ColorDiv" formControlName="compositionId">
            <option value="" disabled>Choose color</option>
            <option *ngFor="let composition of compositionList" [ngValue]="composition.id">{{composition.name}}</option>

          </select>
          <span>
            <button (click)="removeComposition(i)" id="TrashButton"><i class="fa fa-trash fa-lg"></i></button> </span>
          <br>

          <button id="SelectButton" style="font-size: 16px" mat-stroked-button type="submit" (click)="getIndex(i)">
            დამატება
          </button>
        </div>

      </div>

    </form>

    <p> <b> Type: </b>
      {{product?.productType}}</p>
    <p>
      <b>
        Dimentions:
      </b>
    </p>
    <div id="DInput" formGroupName=dimentions>
      <p id="DP"> <b> Width: </b> </p> <input id="input1" type="number" formControlName="width">
      <p id="DP"> <b> Height: </b> </p> <input id="input1" type="number" formControlName="height">
    </div>
    <section id="PSection">
      <p> Prices: </p>
      <div formGroupName=prices>
        <p id="DP"> original value: </p> <input id="input1" type="number" formControlName="originalValue">
        <p id="DP"> percent: </p> <input id="input1" type="number" formControlName="salePercentage">
        <p id="DP"> show percentage value: </p> <input id="input1" type="boolean" formControlName="showSalePercentage">
        <br>
        <p id="DP"> sale: </p> <input type="number" id="input1" formControlName="saleValue">
        <p id="DP"> saled: </p> <input type="number" id="input1" formControlName="saledValue">
      </div>
    </section>
    <button type="submit" id="SButton">
      Submit
    </button>
  </form>
</section>