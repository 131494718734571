import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/shared-services/language.service';
import { environment } from 'src/environments/environment';
import { CreateInstalment, IInstalment } from '../Interfaces/instalment.interface';

@Injectable({
  providedIn: 'root'
})
export class InstalmentService {
host='Instalments';
currentLanguage;

  constructor(
    private httpClient: HttpClient,




  private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }



  createInstalment(data: CreateInstalment){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`, { orderId : data.orderId, descriptions: data.descriptions, titles: data.titles, photos: data.photos}, {
      headers
      })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }



  getInstalments(pageNumber?: number, pageSize?: number)
  //  Observable<Instalment[]> 
   {


    let pageQuery='';
    if(pageNumber && pageSize){
      pageQuery = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    }
    this.host+=pageQuery;

    return this.httpClient.get<IInstalment[]>(`${environment.BaseUrl}/${this.host}`).pipe(
      map((instalments: Array<IInstalment>) => {
        return instalments.map((instalment) => {
          // var instalmentInfo = new Instalment();
          var instalmentInfo;
          return instalmentInfo = {
            id: instalment.id,
            orderId: instalment.orderId,
            titles: instalment.titles.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            descriptions: instalment.descriptions.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            photoUrl: instalment.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/'+ + instalment.id + '/Photos?OrderId=' + instalment.photos[0].orderId : '',
          }
        })
      })
    )

  }




  getInstalmentById(id: number, pageNumber?: number, pageSize?: number)
   {
    let pageQuery='';
    if(pageNumber && pageSize){
      pageQuery = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    }
    this.host+=pageQuery;

    return this.httpClient.get<IInstalment>(`${environment.BaseUrl}/${this.host}/${id}`).pipe(
      map((instalment
        // : Array<IInstalment>
        ) => {
        // return instalments.map((instalment) => {
          // var instalmentInfo = new Instalment();
          var instalmentInfo;
          return instalmentInfo = {
            id: instalment.id,
            orderId: instalment.orderId,
            titles: instalment.titles.map((x) => x),
            descriptions: instalment.descriptions.map((x)=> x),
            photoUrl: instalment.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/'+ + instalment.id + '/Photos?OrderId=' + instalment.photos[0].orderId : '',
          }
        })
          )
  }




  updateInstalmentDescription(id, data){
    const { value, languageId,} = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url=`${environment.BaseUrl}/${this.host}/${id}/Descriptions`;
  this.httpClient.put(url,{value, languageId}, {headers})
  .subscribe({
    next: x => {
      console.log(x);
    },
    error: error => {
      console.error('There was an error!', error);
    }
  });

  }



  updatePhoto(id, dataInfo) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/${id}/Photos`, {
      data: dataInfo.value.data,
      name: dataInfo.value.name,
      extention: dataInfo.value.extention,
      orderId: dataInfo.value.orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }





  deleteProductById(id: number){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, {headers})
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
    //.pipe(
    //  tap(() =>  console.log("error occured!"))
     
      // catchError(error => of(`Bad Promise: ${error}`))
   // );
  }
}