import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { reduce, map, catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IProduct } from '../admin-panel/admin/Interfaces/productDetails.interface';
import { Product } from '../shared/models/product.model';
import { ProductDetails } from '../shared/models/productDetails.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  host = 'Products';

  currentLanguage;
  query: string;


  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }

  getProducts(colorIds?: number[], compositionIds?: number[], startPrice?: number, endPrice?: number, title?: any, pageNumber?: number, pageSize?: number) {
    this.host = 'Products';

    let colorQuery = '';
    if (colorIds) {
      for (let i = 0; i < colorIds.length; i++) {
        if (i == 0) {
          colorQuery += `?Colors=${colorIds[i]}`;
        } else {
          colorQuery += `&Colors=${colorIds[i]}`;
        }
      }
    }

    let compositionQuery = '';
    if (compositionIds) {
      for (let i = 0; i < compositionIds.length; i++) {
        if (i == 0 && colorQuery == '') {
          compositionQuery += `?Compositions=${compositionIds[i]}`;
        } else {
          compositionQuery += `&Compositions=${compositionIds[i]}`;
        }
      }
    }


    let startPriceQuery = ''
    if (startPrice) {
      if (compositionQuery == '' && colorQuery == '') {
        startPriceQuery = `?StartPrice=${startPrice}`;
      } else {
        startPriceQuery = `&StartPrice=${startPrice}`;
      }
    }

    let endPriceQuery = ''
    if (endPrice) {
      if (compositionQuery == '' && colorQuery == '' && startPriceQuery == '') {
        endPriceQuery = `?EndPrice=${endPrice}`;
      } else {
        endPriceQuery = `&EndPrice=${endPrice}`;
      }
    }

    let titleQuery = ''
    if (title) {
      if (compositionQuery == '' && colorQuery == '' && startPriceQuery == '' && endPriceQuery == '') {
        titleQuery = `?Name=${title}`;
      } else {
        titleQuery = `&Name=${title}`;
      }
    }


    let pageQuery = '';
    if (pageNumber && pageSize) {
      if (compositionQuery == '' && colorQuery == '' && startPriceQuery == '' && endPriceQuery == '' && titleQuery == '') {
        pageQuery = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
      } else {
        pageQuery = `&PageNumber=${pageNumber}&PageSize=${pageSize}`;
      }
    }

    
    this.host += colorQuery + compositionQuery + startPriceQuery + endPriceQuery + titleQuery + pageQuery;



    let headers = new HttpHeaders({ 'Access-Control-Expose-Headers': 'X-Pagination' });

    return this.httpClient.get<IProduct[]>(`${environment.BaseUrl}/${this.host}`, { headers: headers, observe: 'response', responseType: 'json' }).pipe(
      map((resp) =>{
      // products: Array<IProduct>)
      // (resp) 
      //  => {
        const mypagination = resp.headers.get('X-Pagination');
        console.log(mypagination);
        var jsonMypagination = JSON.parse(mypagination);
        var totalPages = jsonMypagination.totalPages;
        console.log(totalPages);


      return  resp.body.map((product) => {
        var productInfo = new Product();
        return productInfo = {
          totalPages: totalPages,
          id: product.id,
          photoUrl: product.photos.length > 0
            ? environment.BaseUrl + '/' + 'Products' + '/' +
            product.id +
            '/Photos?OrderId=' + product.photos[0].orderId
            : '',
          name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
          salePercentage: product.prices.salePercentage,
          showSalePercentage: product.prices.showSalePercentage,
          originalValue: product.prices.originalValue,
          saledValue: product.prices.saledValue,
          saleValue: product.prices.saleValue,
        }
      })
    }
    ));

  }



  getPros(pageNumber?: number, pageSize?: number) {
    this.host = 'Products';
    let query = '';
    if (pageNumber) {
      query = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    }
    this.host += query;
    let headers = new HttpHeaders({ 'Access-Control-Expose-Headers': 'X-Pagination' });
    return this.httpClient.get<IProduct[]>(`${environment.BaseUrl}/${this.host}`, { headers: headers, observe: 'response', responseType: 'json' }).pipe(
      map(
        (resp) => {
          const mypagination = resp.headers.get('X-Pagination');
          console.log(mypagination);
          var jsonMypagination = JSON.parse(mypagination);
          var totalPages = jsonMypagination.totalPages;
          console.log(totalPages);

          return resp.body.map((product) => {
            var productInfo = new Product();
            return productInfo = {
              id: product.id,
              photoUrl: product.photos.length > 0
                ? environment.BaseUrl + '/' + 'Products' + '/' +
                product.id +
                '/Photos?OrderId=' + product.photos[0].orderId
                : '',
              name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
              salePercentage: product.prices.salePercentage,
              showSalePercentage: product.prices.showSalePercentage,
              originalValue: product.prices.originalValue,
              saledValue: product.prices.saledValue,
              saleValue: product.prices.saleValue,
            }
          })
          //,
          // {
          //   const mypagination = resp.headers.get('X-Pagination');
          //   console.log(mypagination);
          // }
        }

      )
    )
  }




  getProductByID(id: number) {
    this.host = 'Products';
    return this.httpClient.get<IProduct>(`${environment.BaseUrl}/${this.host}/${id}`).pipe(
      map(
        (product: IProduct) => {
          //  return products.map((productDetails) => {
          var productInfo = new ProductDetails();
          return productInfo = {
            id: product.id,
            name: product.names.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            description: product.descriptions.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            colors: product.colors.map(x => x.names.find((x) => x.language.id == this.currentLanguage)?.value ?? ''),
            compositions: product.compositions.map(x => x.names.find(x => x.language.id == this.currentLanguage)?.value ?? ''),
            width: product.dimentions.width,
            height: product.dimentions.height,
            originalValue: product.prices.originalValue,
            salePercentage: product.prices.salePercentage,
            showSalePercentage: product.prices.showSalePercentage,
            saleValue: product.prices.saleValue,
            saledValue: product.prices.saledValue,
            photoUrl: product.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/' + product.id + '/Photos?OrderId=' + product.photos[0].orderId : '',
          }
        }
      )
    );
  }






}
