import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IBranch } from '../admin-panel/admin/Interfaces/branch.interface';
import { Branch } from '../shared/models/branch.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root',
})
export class BranchService {
  host = 'Branches';
  currentLanguage;


  constructor(private httpClient: HttpClient,
    private languageService: LanguageService
    ) {
      this.currentLanguage = Number(this.languageService.getCurrentLanguageId());

     }


  getBranches(): Observable<Branch[]>
  {
    return this.httpClient
      .get<IBranch[]>(`${environment.BaseUrl}/${this.host}?orderby=orderid enc`)
      .pipe(
        map((branches: Array<IBranch>) => {
          return branches.map((branch) => {
            var branchInfo = new Branch();
            return branchInfo = {
              id: branch.id,
              orderId: branch.orderId,
              isMain: branch.isMain,
              title: branch.titles.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
              workingHours: branch.workingHours.map((m) => m.value ?? ''),
              emails: branch.emails.map((m) => m.value ?? ''),
              photoUrl:
                branch.photos.length > 0
                  ? environment.BaseUrl + '/' + this.host + '/'+
                  branch.id +
                  '/Photos?OrderId=' +
                  branch.photos[0].orderId
                  : '',
              address:
                branch.addresses[0]?.values.find((x) => x.language.id == this.currentLanguage)
                  ?.value ?? '',
              phoneNumbers: branch.phoneNumbers.map((m) => m.value ?? ''),
              latitude: Number(branch.addresses[0]?.latitude),
              longitude: Number(branch.addresses[0]?.longitude),
            };
          });
        })
      );
  }

  mainBranch: Branch;
  getMainBranch() {
    return this.httpClient
      .get<IBranch[]>(`${environment.BaseUrl}/${this.host}?IsMain=true&orderby=orderid enc`)
      .pipe(
        map((res) => {
          if (res.length > 0) {
            return this.mainBranch = {
              id: res[0].id,
              orderId: res[0].orderId,
              isMain: res[0].isMain,
              title: res[0].titles.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
              workingHours: res[0].workingHours.map(m => m.value ?? ''),
              emails: res[0].emails.map(m => m.value ?? ''),
              photoUrl: res[0].photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/'+ res[0].id + '/Photos?OrderId=' + res[0].photos[0].orderId : '',
              address: res[0].addresses[0]?.values.find(x => x.language.id == this.currentLanguage)?.value ?? '',
              phoneNumbers: res[0].phoneNumbers.map(m => m.value ?? ''),
              latitude: Number(res[0].addresses[0]?.latitude),
              longitude: Number(res[0].addresses[0]?.longitude),
            }
          } return null;
        })
      );
  }
}
