<section id="display-size">
  <form [formGroup]='nameForm' (ngSubmit)="editName(nameForm.value)">
    <div formArrayName="names" *ngFor="let name of names.controls; let i=index">
      <p> დასახელება - {{name.value.language.name}}</p>
      <div [formGroupName]=i>
        <input formControlName="value">
      </div>
      <button style="font-size: 16px" mat-stroked-button type="submit"
        (click)="getFormLanguageId(name.value.language.id)">
        რედაქტირება
      </button>
    </div>
  </form>
</section>