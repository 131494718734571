import { Injectable } from '@angular/core';
import { LanguageService } from './shared-services/language.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsDataService {
  currentLanguage;
  contactData;

  contactDataList = [{
    'address': 'მის',
    'tel': 'ტელ',
    'email': 'ელ - ფოსტა',
    'hours': 'სამუშაო საათები',

  },
  {
    'address': 'Adr',
    'tel': 'Tel',
    'email': 'Mail',
    'hours': 'Bh',
  }
  ];

  constructor(private languageService: LanguageService) {

    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }


  getContactsData() {

    if (this.currentLanguage == 1) {
      return this.contactData = this.contactDataList[0];
    }
    else {
      return this.contactData = this.contactDataList[1];
    }
  }
}
