<section id="Employee-create" id="display-size">
    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">
        <div style="max-width:500px" class="image-upload">
            <img style="max-width:500px" [src]="photoUrl" />
            <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
        </div>

        <p>orderId</p>
        <input id="input1" formControlName="orderId">

        <div class="flex" formArrayName="fullNames">
            <div>
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input id="input1" formControlName="value">
            </div>
        </div>
        <div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input id="input1" formControlName="value">
            </div>
        </div>
        </div>

        <div class="flex" formArrayName="positions">
            <div>
            <p>პოზიცია </p>
            <div [formGroupName]=0>
                <input id="input1" formControlName="value">
            </div>
        </div>
        <div>
            <p>Position</p>
            <div [formGroupName]=1>
                <input id="input1" formControlName="value">
            </div>
        </div>
        </div>
        <button type="submit" id="SButton">
            Submit
        </button>
    </form>


</section>