import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BranchService } from '../services/branch.service';
import { ContactsDataService } from '../services/contacts-data.service';
import { ContactsService } from '../services/contacts.service';
import { TitlesService } from '../services/titles.service';
import { Branch } from '../shared/models/branch.model';
import { Contacts } from '../shared/models/contacts.model';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  titles;
  contactsData;
  branchSubscription?: Subscription;
  contactSubscription?: Subscription
  subscriptions: Subscription[] = [];

  lat: number = 41.7131544795735;
  lng: number = 44.755897695898206;
  zoom: number = 14;

  constructor(
    private branchService: BranchService,
    private contactsService: ContactsService,
    private titlesService: TitlesService,
    private contactsDataService: ContactsDataService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.fetchBranches();
    this.fetchContacts();
    this.titles = this.titlesService.getTitles();
    this.contactsData = this.contactsDataService.getContactsData();
  }

  contactBanner: string;
  fetchContacts() {
    this.contactSubscription = this.contactsService.fetchContact().subscribe((data) => {
      this.contactBanner = data.photoUrl;
    })
    this.subscriptions.push(this.contactSubscription);
  }

  branchList: Array<Branch> = [];
  fetchBranches() {
    this.branchSubscription = this.branchService.getBranches().subscribe((data) => {
      data.forEach((data) => {
        this.branchList.push(data);
        this.lat = Number(data.latitude);
        this.lng = Number(data.longitude);
      }
      );
      this.subscriptions.push(this.branchSubscription);
    });
  }
}
