import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColorService } from '../services/color.service';
import { CompositionService } from '../services/composition.service';
import { ProductNameService } from '../services/product-name.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss'],
})
export class ProductUpdateComponent implements OnInit, OnDestroy {
  product;
  productSubscription?: Subscription;
  colorSubscription?: Subscription;
  compositionSubscription?: Subscription;
  subscriptions?: Subscription[];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private productService: ProductService,
    private colorService: ColorService,
    private compositionService: CompositionService,
    private productNameService: ProductNameService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  form: FormGroup;
  nameForm: FormGroup;
  descForm: FormGroup;
  colorForm: FormGroup;
  compositionForm: FormGroup;
  photoForm: FormGroup;
  id;
  nameList = [];
  ngOnInit(): void {
    this.form = this.fb.group({
      productType: 1,
      calculator: 1,
      dimentions: this.fb.group({
        width: 0,
        height: 0,
      }),

      prices: this.fb.group({
        originalValue: 0,
        salePercentage: 0,
        showSalePercentage: true,
        saleValue: 0,
        saledValue: 0,
      }),
    });

    this.nameForm = this.fb.group({
      names: this.fb.array([]),
    });

    this.descForm = this.fb.group({
      descriptions: this.fb.array([]),
    });

    this.colorForm = this.fb.group({
      colors: this.fb.array([]),
    });

    this.compositionForm = this.fb.group({
      compositions: this.fb.array([]),
    });

    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: 1,
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));

    this.fetchProductById(this.id);
    this.getColorList();
    this.getCompositionList();
  }

  fetchProductById(id) {
    this.productSubscription = this.productService
      .getProductByID(this.id)
      .subscribe((data) => {
        this.product = data;

        data.names.forEach((element) => this.addName(element));

        data.descriptions.forEach((element) => this.addDescription(element));

        data.colors.forEach((element) => {});

        this.photoForm.patchValue({
          ...this,
        });

        this.photoUrl = data.photoUrl;

        this.form.controls.dimentions.patchValue({
          width: data.width,
          height: data.height,
        });

        this.form.controls.prices.patchValue({
          originalValue: data.originalValue,
          salePercentage: data.salePercentage,
          showSalePercentage: data.showSalePercentage,
          saleValue: data.saleValue,
          saledValue: data.saledValue,
        });
      });
    this.subscriptions.push(this.productSubscription);
  }

  //photos

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  photoUrl;
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.productService.updatePhoto(this.id, this.photoForm);
  }

  //names

  get names(): FormArray {
    return <FormArray>this.nameForm.get('names');
  }

  addName(data): void {
    this.names.push(this.buildNames(data));
  }

  buildNames(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  nameId;
  getFormLanguageIdName(id: number) {
    this.nameId = id;
  }

  editName(data) {
    var model = data.names?.find((x) => x.language.id == this.nameId);

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.productService.updateProductName(this.id, updateModel);
  }

  //descriptions
  get descriptions(): FormArray {
    return <FormArray>this.descForm.get('descriptions');
  }

  addDescription(data): void {
    this.descriptions.push(this.buildDescriptions(data));
  }

  buildDescriptions(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  descriptionId;
  getFormLanguageIdDesc(id: number) {
    this.descriptionId = id;
  }

  editDesc(data) {
    var model = data.descriptions?.find(
      (x) => x.language.id == this.descriptionId
    );

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.productService.updateProductDescription(this.id, updateModel);
  }

  colorList = [];

  getColorList() {
    this.colorSubscription = this.colorService.getColors().subscribe((data) => {
      data.forEach((element) => {
        console.log(element);
        this.colorList?.push(element);
      });
    });
    this.subscriptions.push(this.colorSubscription);
  }

  compositionList = [];

  getCompositionList() {
    this.compositionSubscription = this.compositionService
      .getCompositions()
      .subscribe((data) => {
        data.forEach((element) => {
          console.log(element);
          this.compositionList?.push(element);
        });
      });
    this.subscriptions.push(this.compositionSubscription);
  }

  get colors(): FormArray {
    return <FormArray>this.colorForm.get('colors');
  }
  addColor(): void {
    this.colors?.push(this.buildColors());
  }

  removeColor(id: number) {
    this.colors.removeAt(id);
  }

  removeDataColor(productId, colorId: number) {
    this.productService.removeDataColor(productId, colorId);
  }

  buildColors(): FormGroup {
    return this.fb.group({
      colorId: 0,
    });
  }

  addComposition(): void {
    this.compositions.push(this.buildCompositions());
  }
  removeComposition(id: number) {
    this.compositions.removeAt(id);
  }

  removeDataComposition(productId, colorId: number) {
    this.productService.removeDataComposition(productId, colorId);
  }

  buildCompositions(): FormGroup {
    return this.fb.group({
      // value: false,
      compositionId: 0,
    });
  }

  get compositions(): FormArray {
    return <FormArray>this.compositionForm.get('compositions');
  }

  editComposition(data) {
    var model = data.compositions[this.index];
    this.productService.createProductComposition(this.id, model);
  }

  editColor(data) {
    var model = data.colors[this.index];
    this.productService.createProductColor(this.id, model);
  }

  index;
  getIndex(id) {
    this.index = id;
  }

  onSubmit(data) {
    this.productService.updateProductById(this.id, data);
    this.form.reset();
  }
}
