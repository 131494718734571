import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IInstalment } from '../admin-panel/admin/Interfaces/instalment.interface';
import { Instalment } from '../shared/models/instalment.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InstalmentService {
  host = 'Instalments';

  currentLanguage;

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
   }


  getInstalments(pageNumber?: number, pageSize?: number): Observable<Instalment[]> {
this.host = 'Instalments';

    let pageQuery='';
    if(pageNumber && pageSize){
      pageQuery = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
      
    }
    this.host+=pageQuery;

    return this.httpClient.get<IInstalment[]>(`${environment.BaseUrl}/${this.host}`).pipe(
      map((instalments: Array<IInstalment>) => {
        return instalments.map((instalment) => {
          var instalmentInfo = new Instalment();
          return instalmentInfo = {
            id: instalment.id,
            orderId: instalment.orderId,
            title: instalment.titles.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            description: instalment.descriptions.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
            photoUrl: instalment.photos.length > 0 ? environment.BaseUrl + '/' + 'Instalments' + '/' + instalment.id + '/Photos?OrderId=' + instalment.photos[0].orderId : '',
          }
        })
      })
    )

  }

}
