import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginSubscription?: Subscription;
  inputForm;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {
    this.inputForm = formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],

    });
  }
  // ngOnDestroy(): void {
  //   this.loginSubscription.unsubscribe();
  // }

  ngOnInit(): void {
  }
  onSubmit(login) {
    this.loginSubscription = this.loginService.login(login).subscribe(result => {
      console.log(result);
      window.location.href = '';
      // this.router.navigateByUrl('');

    })
  }


}
