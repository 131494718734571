<section class="product-section">
  <div class="product">
    <div class="title-text">
      <h3><b>{{titles.prods}}</b></h3>
      <hr class="title-hr" />
    </div>


    <div fxHide fxShow.lt-lg class="mobileFilter">
      <img src="../../assets/filter_arrow.svg">
      <div class="text">ფილტრი</div>
      <!-- This button triggers the overlay and is it's origin -->
      <a (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <i class="fa fa-angle-right" aria-hidden="true"></i>

      </a>
      <!-- <div class="clear"></div> -->
    </div>







    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
      <ul class="example-list">
        <section class="filter-section" [formGroup]="features">
          <h4>{{filterData.filterData}}</h4>
          <p class="align">{{filterData.seacrh}}</p>
          <div class="titleSearch">
            <input formControlName="title" placeholder={{filterData.title}} class="titleInput" />
          </div>
          <p class="align">{{filterData.price}}</p>
          <div class="priceSearch">
            <div class="priceContainer">
              <input formControlName="from" class="priceInput" placeholder={{filterData.from}} />
              <input formControlName="to" class="priceInput" placeholder={{filterData.to}} />
            </div>

          </div>
          <p class="align">{{filterData.color}}</p>


          <div formArrayName="colors" *ngFor="let color of colors.controls; let i=index">
            <div [formGroupName]="i">
              <p class="style">
                <mat-checkbox formControlName="value">{{col[i].name}}</mat-checkbox>
              </p>
            </div>
          </div>

          <p class="align">{{filterData.composition}}</p>

          <div formArrayName="compositions" *ngFor="let composition of compositions.controls; let i=index">
            <div [formGroupName]="i">
              <p class="style">
                <mat-checkbox formControlName="value">{{comp[i].name}}</mat-checkbox>
              </p>
            </div>
          </div>

        </section>
      </ul>
    </ng-template>







    <div fxLayout.xs="column" fxLayout="row wrap" ngClass.md="lt-md-display">
      <!-- fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" -->
      <div fxHide.xs fxHide.sm fxHide.md fxFlex.gt-md="0 1 calc(30%)">

        <section class="filter-section" [formGroup]="features">
          <h4>{{filterData.filterTitle}}</h4>
          <p class="align">{{filterData.search}}</p>

          <div class="titleSearch">
            <input formControlName="title" placeholder={{filterData.title}} class="titleInput" />
          </div>
          <p class="align">{{filterData.price}}</p>
          <div class="priceSearch">
            <div class="priceContainer">
              <input formControlName="from" class="priceInput" placeholder={{filterData.from}} />
              <input formControlName="to" class="priceInput" placeholder={{filterData.to}} />
            </div>

          </div>





          <p class="align">{{filterData.color}}</p>
          <div formArrayName="colors" *ngFor="let color of colors.controls; let i=index">

            <div [formGroupName]="i">
              <p class="style">
                <mat-checkbox formControlName="value">{{col[i].name}}</mat-checkbox>
              </p>
            </div>
            <!-- <section class="example-section" [formGroupName]="i">
              <h4>You chose:</h4>
              {{colors.value | json}}
            </section> -->
          </div>

          <p class="align">{{filterData.composition}}</p>

          <div formArrayName="compositions" *ngFor="let composition of compositions.controls; let i=index">
            <div [formGroupName]="i">
              <p class="style">
                <mat-checkbox formControlName="value">{{comp[i].name}}</mat-checkbox>
              </p>
            </div>
            <!-- <section class="example-section" [formGroupName]="i">
              <h4>You chose:</h4>
              {{compositions.value | json}}
            </section> -->
          </div>



        </section>
      </div>
      <div fxFlex.md="0 1 calc(70%)" fxFlex.gt-md="0 1 calc(70%)">
        <div fxLayout="row wrap" fxLayoutGap="20px" style="margin-left:20px">
          <div class="prod" fxFlex.xs="0 1 calc(50%-20px)" fxFlex.sm="0 1 calc(50%-20px)"
            fxFlex.md="0 1 calc(33.3%-20px)" fxFlex.gt-md="0 1 calc(33.3%-20px)"
            *ngFor="let product of products;  let i=index">
            <a [routerLink]="['/products-page', product.id]">
              <div>
                <div ngClass.gt-md="gt-md-position" ngClass.lt-lg="lt-lg-position">
                  <img [src]='product?.photoUrl' ngClass.gt-md="gt-md-width" ngClass.lt-lg="lt-lg-width">
                  <div *ngIf="product.showSalePercentage" ngClass.gt-md="gt-md-relative" ngClass.lt-lg="lt-lg-relative">
                    -{{product.salePercentage}}%
                  </div>
                </div>
              </div>
            </a>

            <div class="door-text">
              <p><b>{{product.name}}</b></p>
              <p>
                <span [ngClass]="product.saleValue || product.salePercentage ? 'strikethrough' : 'null'"
                  style="margin-right: 15%;"> {{product.originalValue | currency:'GEL':'symbol-narrow'}}</span>
                <span *ngIf="product.saleValue || product.salePercentage" style="color: red;">
                  {{product.saledValue | currency:'GEL':'symbol-narrow' }}
                </span>
              </p>
            </div>
          </div>


        </div>
        <div style="display: flex;
align-items: center;
justify-content: center;
/* margin-top: 30px; */
">
          <button *ngIf="show" class="more" (click)="showMore()" style="    border-radius: 50px;
        width: 350px;
        height: 86px;
        border: none;
        background-color: #F2F3F5;
             ">Show More</button>
        </div>
      </div>

    </div>








    <!-- <mat-paginator [length]="100"
 [pageSize]="10"
 [pageSizeOptions]="[5, 10, 25, 100]"
 (page)="onPaginateChange($event)">
</mat-paginator>
<div>Page index: {{index}}</div> -->

  </div>
</section>