<section id="display-size">
  <div class="image-upload">
    <img style="max-width: 500px;" [src]="photoUrl" />
    <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
  </div>

  <form [formGroup]='contactsForm' (ngSubmit)="editContacts(contactsForm.value)">
    <p>fb UserID: </p>
    <input id="input1" formControlName="facebookUserId">
    <p> fb:</p>

    <input id="input1" formControlName="facebook">
    <br>

    <p> Insta: </p>
    <input id="input1" formControlName="instagram">
    <p> Youtube: </p>
    <input id="input1" formControlName="youtube">
    <br>

    <button id="SButton" mat-stroked-button type="submit">
      რედაქტირება
    </button>
  </form>
</section>