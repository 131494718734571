import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InstalmentService } from '../services/instalment.service';

@Component({
  selector: 'app-instalment-list',
  templateUrl: './instalment-list.component.html',
  styleUrls: ['./instalment-list.component.scss'],
})
export class InstalmentListComponent implements OnInit, OnDestroy {
  instalmentSubscription?: Subscription;
  
  constructor(
    private instalmentService: InstalmentService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    this.instalmentSubscription.unsubscribe();
  }

  instalmentList = [];

  ngOnInit(): void {
    this.instalmentSubscription = this.instalmentService
      .getInstalments()
      .subscribe((data) => {
        data.forEach((element) => {
          this.instalmentList.push(element);
        });
      });
  }
  redirectToCreateInstalment() {
    this.router.navigateByUrl('instalment-create');
  }

  removeInstalment(id: number) {
    this.instalmentService.deleteProductById(id);
  }
}
