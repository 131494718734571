import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BranchService } from '../services/branch.service';

@Component({
  selector: 'app-contact-update',
  templateUrl: './contact-update.component.html',
  styleUrls: ['./contact-update.component.scss'],
})
export class ContactUpdateComponent implements OnInit, OnDestroy {
  branchSubscription?: Subscription;

  id;
  branch;
  nameForm: FormGroup;
  addressForm: FormGroup;
  photoForm: FormGroup;
  hoursForm: FormGroup;
  emailForm: FormGroup;
  numberForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private branchService: BranchService
  ) {}
  ngOnDestroy(): void {
    this.branchSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.nameForm = this.fb.group({
      names: this.fb.array([]),
    });
    this.addressForm = this.fb.group({
      addresses: this.fb.array([]),
    });
    this.hoursForm = this.fb.group({
      workingHours: this.fb.array([]),
    });
    this.emailForm = this.fb.group({
      emails: this.fb.array([]),
    });
    this.numberForm = this.fb.group({
      phoneNumbers: this.fb.array([]),
    });
    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: 0,
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getBranchById(this.id);
  }

  getBranchById(id: number) {
    this.branchSubscription = this.branchService
      .getBranchById(id)
      .subscribe((data) => {
        this.branch = data;

        data.titles.forEach((element) => {
          this.addName(element);
        });

        // data.address.forEach((element) => {
        //   this.addAddress(element);
        // })

        this.addAddress(data.address);
        data.workingHours.forEach((element) => {
          this.addWorkingHour(element);
        });

        data.emails.forEach((element) => {
          this.addEmail(element);
        });

        data.phoneNumbers.forEach((element) => {
          this.addPhoneNum(element);
        });

        this.photoForm.patchValue({
          orderId: data.photoOrderId,
        });

        this.photoUrl = data.photoUrl;
      });
  }

  //name
  get names(): FormArray {
    return <FormArray>this.nameForm.get('names');
  }

  addName(data): void {
    this.names.push(this.buildNames(data));
  }

  buildNames(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  languageId;
  getFormLanguageId(id: number) {
    this.languageId = id;
  }

  editName(data) {
    var model = data.names?.find((x) => x.language.id == this.languageId);

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.branchService.updateBranchName(this.id, updateModel);

    // this.fetchAboutInfo();
  }

  //address

  get addresses(): FormArray {
    return <FormArray>this.addressForm.get('addresses');
  }

  getAddresses(form) {
    console.log(form.get('addresses')?.controls);
    return form?.controls?.addresses?.controls;
  }

  getValues(form) {
    //  console.log(form.get('values')?.controls);
    return form?.controls?.values?.controls;
  }

  addAddress(data): void {
    this.addresses.push(this.buildAddresss(data));
  }

  buildAddresss(data): FormGroup {
    return this.fb.group({
      orderId: data.orderId,
      latitude: data.latitude,
      longitude: data.longitude,
      values: this.fb.array([
        this.fb.group({
          value: data.values[0]?.value,
          languageId: data.values[0]?.language.id,
        }),
        this.fb.group({
          value: data.values[1]?.value,
          languageId: data.values[1]?.language.id,
        }),
      ]),
    });
  }

  x;
  editAddress(data) {
    var model = data.addresses[0];
    this.branchService.updateBranchAddress(this.id, model);
  }

  //workingHours
  get workingHours(): FormArray {
    return <FormArray>this.hoursForm.get('workingHours');
  }

  addWorkingHour(data): void {
    this.workingHours.push(this.buildWorkingHour(data));
  }

  buildWorkingHour(data): FormGroup {
    return this.fb.group({
      value: data.value,
      orderId: data.orderId,
    });
  }

  orderId;
  getFormOrderId(id: number) {
    this.orderId = id;
  }
  editHour(data) {
    var model = data.workingHours?.find((x) => x.orderId == this.orderId);

    var updateModel = {
      value: model?.value,
      orderId: model?.orderId,
    };

    this.branchService.updateBranchWorkingHour(this.id, updateModel);
  }

  removeWorkingHour(id: number) {}

  //emails
  get emails(): FormArray {
    return <FormArray>this.emailForm.get('emails');
  }

  addEmail(data): void {
    this.emails.push(this.buildEmail(data));
  }

  buildEmail(data): FormGroup {
    return this.fb.group({
      value: data.value,
      orderId: data.orderId,
    });
  }
  editEmail(data) {
    var model = data.emails?.find((x) => x.orderId == this.orderId);

    var updateModel = {
      value: model?.value,
      orderId: model?.orderId,
    };

    this.branchService.updateBranchEmail(this.id, updateModel);
  }
  removeEmail(id: number) {}

  //numberForm
  get phoneNumbers(): FormArray {
    return <FormArray>this.numberForm.get('phoneNumbers');
  }

  addPhoneNum(data): void {
    this.phoneNumbers.push(this.buildPhoneNum(data));
  }

  buildPhoneNum(data): FormGroup {
    return this.fb.group({
      value: data.value,
      orderId: data.orderId,
    });
  }

  editPhoneNum(data) {
    var model = data.phoneNumbers?.find((x) => x.orderId == this.orderId);

    var updateModel = {
      value: model?.value,
      orderId: model?.orderId,
    };

    this.branchService.updateBranchPhoneNum(this.id, updateModel);
  }

  removeNumber(id: number) {}

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  photoUrl;
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    // this.photoForm.patchValue([
    //   { data: base64File }
    // ])
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.branchService.updatePhoto(this.id, this.photoForm);
  }
}
