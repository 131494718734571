import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../shared/models/employee.model';
import { Instalment } from '../shared/models/instalment.model';
import { InstalmentService } from '../services/instalment.service';
import { AboutServiceService } from '../services/about-service.service';
import { ProductService } from '../services/product.service';
import { HomeHeaderService } from '../services/home-header.service';
import { HomeHeader } from '../shared/models/homeHeader.model';
import { About } from '../shared/models/about.model';
import { TitlesService } from '../services/titles.service';
import { SubscriberService } from '../services/subscriber.service';
import { Branch } from '../shared/models/branch.model';
import { BranchService } from '../services/branch.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy  {
  titles;
  subscriberText;
  mail = '';
  aboutSubscription?: Subscription;
  employeeSubscription?: Subscription;
  mainBranchSubscription?: Subscription;
  productSubscription?: Subscription;
  instalmentsSubscription?: Subscription;
  homeHeadersSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private productService: ProductService,
    private aboutServiceService: AboutServiceService,
    private employeeService: EmployeeService,
    private instalmentService: InstalmentService,
    private homeHeaderService: HomeHeaderService,
    private titlesService: TitlesService,
    private subscriberService: SubscriberService,
    private branchService: BranchService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchHomeHeaders();
    this.fetchProducts(1, 6);
    this.fetchEmployees(1, 3);
    this.fetchInstalments(1, 2);
    this.fetchAbout();
    this.fetchMainBranch();
    this.titles = this.titlesService.getTitles();
    this.subscriberText = this.titlesService.getSubscriptionText();
  }

  aboutText: string;
  fetchAbout() {
    this.aboutSubscription = this.aboutServiceService.getAbout().subscribe((data) => {
      this.aboutText = data.shortDescription
    })
    this.subscriptions.push(this.aboutSubscription);
  }

  members: Employee[] = [];
  fetchEmployees(pageNumber, pageQuery) {
    this.employeeSubscription = this.employeeService.getEmployees(pageNumber, pageQuery).subscribe((data) => {
      data.forEach((element) => {
        this.members.push(element);
      });
    });
    this.subscriptions.push(this.employeeSubscription);
  }
  lat;
  lng;

  zoom: number = 14;
  mainBranch?: Branch;
  fetchMainBranch() {
    this.mainBranchSubscription = this.branchService.getMainBranch().subscribe((element) => {
      this.mainBranch = element;
      this.lat = element.latitude;
      this.lng = element.longitude;
    })
    this.subscriptions.push(this.mainBranchSubscription);
  }

  products: Array<any> = [];

  fetchProducts(pageNumber, pageQuery) {
    this.productSubscription = this.productService.getProducts(null, null, null, null, null, pageNumber, pageQuery)
      .subscribe((data) => {
        data.forEach((element) => {
          this.products.push(
            element
          )
        })
      })
    this.subscriptions.push(this.productSubscription);
  }

  instalments = [];
  fetchInstalments(pageNumber, pageQuery) {
    this.instalmentsSubscription = this.instalmentService.getInstalments(pageNumber, pageQuery).subscribe((data) => {
      data.forEach((element) => {
        this.instalments.push(
          element
        );
      });
    })
    this.subscriptions.push(this.instalmentsSubscription);
  }

  imageObject: Array<object> = [{
    image: 'assets/img/slider/1.jpg',
    thumbImage: 'assets/img/slider/1_min.jpeg',
    alt: 'alt of image',
    title: 'title of image'
  }, {
    image: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    thumbImage: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    title: 'Image title', //Optional: You can use this key if want to show image with title
    alt: 'Image alt', //Optional: You can use this key if want to show image with alt
    order: 1 //Optional: if you pass this key then slider images will be arrange according @input: slideOrderType
  }
  ];

  homeHeaders: HomeHeader[] = [];
  fetchHomeHeaders() {
    this.homeHeadersSubscription = this.homeHeaderService.getHomeHeaders().subscribe(data => {
      data.forEach(element => {
        this.homeHeaders.push(
          element
        )
      }
      )
    })
    this.subscriptions.push(this.homeHeadersSubscription);
  }

  clickme() {
    this.subscriberService.postSubscribers(this.mail);
  }
  postSubscribers(email) {

  }

}

