<!-- <div class="footer">
  <div id="LeftFooter">
    <ul>
      <li> <a [routerLink]="['']">{{navbar.main}}</a></li>
      <li><a [routerLink]="['/about-page']">{{navbar.about}}</a></li>
      <li><a [routerLink]="['/products-page']">{{navbar.prods}}</a></li>
      <li><a [routerLink]="['/services-page']">{{navbar.service}}</a></li>
      <li><a [routerLink]="['/instalment-page']">{{navbar.instalments}}</a></li>
      <li> <a [routerLink]="['/contact-page']"> {{navbar.contacts}}</a></li>
    </ul>
  </div>
  <div id="MiddleFooter">
    <a [routerLink]="['/facebook']" target="_blank">
      <div id="FooterSM">

        <img id="SMImg" alt="Facebook" src='../../assets/facebook.png'>


        <p id="SMP">Facebook</p>

      </div>
    </a>
    <a [routerLink]="['/instagram']" target="_blank">
      <div id="FooterSMInst">

        <img id="SMImg" alt="Instagram" src='../../assets/instagram.png'>
        <p id="SMP">Instagram</p>


      </div>
    </a>

    <div id="RigtFooterSMInst"> <a (click)="clickEvent('geo')" id="SMPLink"> ქარ </a> <img id="SMPDot"
        src="../../assets/dot.png" alt="Dot"> <a (click)="clickEvent('eng')" id="SMPEngLink"> ENG</a>
    </div>
  </div>
  <div id="RightFooter">
    <div id="RigtFooterSM"> <img src="../../assets/map-marker-w.png" alt="Loc">
      <p id="RightDivP">{{mainBranch?.address}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/phone-w.png" alt="Phone">
      <p id="RightDivP">{{mainBranch?.phoneNumbers}}; <br>
      </p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/email-w.png" alt="Email">
      <p id="RightDivP">{{mainBranch?.emails}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/clock-w.png" alt="Clock">
      <p id="RightDivP">{{mainBranch?.workingHours}}<br>
      </p>
    </div>
  </div>
</div>
<div id="BottomFooter">
  <p>LTD NEWDOORS - ALL RIGHTS RESERVED {{date}}</p>

</div> -->



<!-- 22222

<div class="footer">
  <div id="LeftFooter">
    <ul>
      <li> <a [routerLink]="['']">{{navbar.main}}</a></li>
      <li><a [routerLink]="['/about-page']">{{navbar.about}}</a></li>
      <li><a [routerLink]="['/products-page']">{{navbar.prods}}</a></li>
      <li><a [routerLink]="['/services-page']">{{navbar.service}}</a></li>
      <li><a [routerLink]="['/instalment-page']">{{navbar.instalments}}</a></li>
      <li> <a [routerLink]="['/contact-page']"> {{navbar.contacts}}</a></li>
    </ul>
  </div>
  <div fxShow.lt-md="false" fxShow="true" id="MiddleFooter">
    <a [routerLink]="['/facebook']" target="_blank">
      <div id="FooterSM">

        <img id="SMImg" alt="Facebook" src='../../assets/facebook.png'>


        <p id="SMP">Facebook</p>

      </div>
    </a>
    
    <a [routerLink]="['/youtube']" target="_blank" >
      <div id="FooterSMYoutube">

        <img  id="YoutubeImg" src="../../assets/youtube.png" alt="Youtube">

        <p id="SMPYoutube"> Youtube </p>
      
      </div>

    </a>
    <a [routerLink]="['/instagram']" target="_blank">
      <div id="FooterSMInst">

        <img id="SMImg" alt="Instagram" src='../../assets/instagram.png'>
        <p id="SMP">Instagram</p>
       

      </div>
    </a>

    <div id="RigtFooterSMInst"> <a (click)="clickEvent('geo')" id="SMPLink"> ქარ </a> <img id="SMPDot"
        src="../../assets/dot.png" alt="Dot"> <a (click)="clickEvent('eng')" id="SMPEngLink"> ENG</a>
    </div>
  </div>
  <div id="RightFooter">
    <div id="RigtFooterSM"> <img src="../../assets/map-marker-w.png" alt="Loc">
      <p id="RightDivP">{{mainBranch?.address}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/phone-w.png" alt="Phone">
      <p id="RightDivP">{{mainBranch?.phoneNumbers}} <br>
      </p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/email-w.png" alt="Email">
      <p id="RightDivP">{{mainBranch?.emails}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/clock-w.png" alt="Clock">
      <p id="RightDivP">{{mainBranch?.workingHours}}<br>
      </p>
    </div>
  </div>
</div>
<div id="BottomFooter">
  <p>LTD NEWDOORS - ALL RIGHTS RESERVED {{date}}</p>

</div> -->




<footer>
<div class="footer">
  <div class="LeftFooter" ngClass.xs="xs-Leftfooter">
    <ul  ngClass.gt-xs="gt-xs-leftfooter-ul" ngClass.xs="xs-leftfooter-ul">
      <li> <a [routerLink]="['']">{{navbar.main}}</a></li>
      <li><a [routerLink]="['/about-page']">{{navbar.about}}</a></li>
      <li><a [routerLink]="['/products-page']">{{navbar.prods}}</a></li>
      <li><a [routerLink]="['/services-page']">{{navbar.service}}</a></li>
      <li><a [routerLink]="['/instalment-page']">{{navbar.instalments}}</a></li>
      <li> <a [routerLink]="['/contact-page']"> {{navbar.contacts}}</a></li>
    </ul>
  </div>
  <div fxShow.lt-md="false" fxShow="true" id="MiddleFooter">
    <a class="routerlink" href={{socialContact?.facebook}} target="_blank">
      <div id="FooterSM">
        <div id="FLeft">

        <img id="SMImg" alt="Facebook" src='../../assets/facebook.png'>
      </div>
      <div id="FRight">
        <p id="SMP">Facebook</p>
      </div>
      </div>
    </a>
    
    <a class="routerlink" href={{socialContact?.youtube}} target="_blank" >
      <div id="FooterSMYoutube">
        <div id="YoutubeLeft">
        <img  id="YoutubeImg" src="../../assets/youtube.-footer.png" alt="Youtube">
      </div>
      <div id="YoutubeRight">
        <p id="SMP"> Youtube </p>
      </div>
      
      </div>

    </a>
    <a class="routerlink" href={{socialContact?.instagram}} target="_blank">
      <div id="FooterSMInst">
<div id="ILeft">
        <img id="SMImg" alt="Instagram" src='../../assets/instagram.png'>
      </div>
      <div id="IRight">
        <p id="SMP">Instagram</p>
      </div>
       

      </div>
    </a>
    <!-- <div id="FooterSM">
      <a [routerLink]="['/youtube']" target="_blank">
      <img id="SMImg" alt="Facebook">
      <p id="SMP">Youtube</p>
    </a> -->
    <!-- </div> -->
    <div id="RigtFooterSMInst"> <a (click)="clickEvent('geo')" id="SMPLink"> ქარ </a> <img id="SMPDot"
        src="../../assets/dot.png" alt="Dot"> <a (click)="clickEvent('eng')" id="SMPEngLink"> ENG</a>
    </div>
  </div>
  <div  fxShow.lt-sm="false" fxShow="true" id="RightFooter">
    <div id="RigtFooterSM"> <img src="../../assets/map-marker-w.png" alt="Loc">
      <p id="RightDivP">{{mainBranch?.address}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/phone-w.png" alt="Phone">
      <p id="RightDivP">{{mainBranch?.phoneNumbers}} <br>
      </p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/email-w.png" alt="Email">
      <p id="RightDivP">{{mainBranch?.emails}}</p>
    </div>
    <div id="RigtFooterSM"> <img src="../../assets/clock-w.png" alt="Clock">
      <p id="RightDivP">{{mainBranch?.workingHours}}<br>
      </p>
    </div>
  </div>
</div>
<div id="BottomFooter">
  <p>LTD NEWDOORS - ALL RIGHTS RESERVED {{date}}</p>

</div>
</footer>