import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { reduce, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IEmployee } from '../admin-panel/admin/Interfaces/employee';
import { Employee } from '../shared/models/employee.model';
import { LanguageService } from './shared-services/language.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class EmployeeService {


  host = 'Employees';

  currentLanguage;

  constructor(private httpClient: HttpClient,
    private languageService: LanguageService) {
      this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }


  getEmployees(pageNumber?: number, pageSize?: number): Observable<Employee[]> {
this.host='Employees';
    let pageQuery='';
    if(pageNumber && pageSize){
      pageQuery = `?PageNumber=${pageNumber}&PageSize=${pageSize}&orderby=orderid enc`;
    }else {
      pageQuery=`?orderby=orderid enc`;
    }
    this.host+=pageQuery;

    return this.httpClient.get<IEmployee[]>(`${environment.BaseUrl}/${this.host}`).pipe(
      map((employees: Array<IEmployee>) => {
        return employees.map((employee) => {
          var member = new Employee();
          member.id = employee.id;
          member.orderId = employee.orderId;
          member.fullName =
            employee.fullNames.find((x) => (x.language.id == this.currentLanguage))?.value ?? '';
          member.position =
            employee.positions.find((x) => (x.language.id == this.currentLanguage))?.value ?? '';
          member.photoUrl =
            employee.photos.length > 0
              ? 
              environment.BaseUrl + '/' +
              'Employees/'
              +
                employee.id +
                '/Photos?OrderId=' +
                employee.photos[0].orderId
              : '';

          return member;
        });
      })
    );
  }
}
