import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IComposition } from '../admin-panel/admin/Interfaces/composition.interface';
import { Composition } from '../shared/models/composition.model';
import { LanguageService } from './shared-services/language.service';

@Injectable({
  providedIn: 'root'
})
export class CompositionService {
  host = 'Compositions/?orderby=id enc';
  currentLanguage;


  constructor(
    private hhtp: HttpClient,
    private languageService: LanguageService
  ) { 
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());

  }

getCompositions(): Observable<Composition[]>{
  return this.hhtp
  .get<IComposition[]>(`${environment.BaseUrl}/${this.host}`)
  .pipe(
    map((compositions: Array<IComposition>) =>{
      return compositions.map((composition) =>{
        var compositionList = new Composition();
        return compositionList={
          id: composition.id,
          name: composition.names.find(x => x.language.id==this.currentLanguage)?.value ?? '',
                  };
      })
    }),tap(x => console.log(x))
  )
}


}
