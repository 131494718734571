import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { InstalmentService } from 'src/app/admin-panel/admin/services/instalment.service';
import { CreateInstalment } from '../Interfaces/instalment.interface';
import { CreateLanguagedObject } from '../Interfaces/description.interface';
import { Description } from '../models/description.model';
import { CreatePhoto } from '../Interfaces/photo.interface';

@Component({
  selector: 'app-instalment-create',
  templateUrl: './instalment-create.component.html',
  styleUrls: ['./instalment-create.component.scss'],
})
export class InstalmenCreateComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private instalmentService: InstalmentService
  ) {}

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      orderId: null,
      photoUrl: '',
      descriptions: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
    });
  }

  get orderId() {
    return this.form.get('orderId');
  }

  get descriptions(): FormArray {
    return <FormArray>this.descriptions.get('descriptions');
  }

  photoUrl;
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.form.controls.photoUrl.setValue(base64File);
    console.log(base64File);
    var photo = {
      data: base64File,
      name: 'string',
      extention: 'string',
      orderId: 0,
    };
  }

  //submits inputForm fields when clicked submit button
  onSubmit(data) {
    var model = new CreateInstalment();
    model.orderId = Number(data.orderId);
    var descriptions: CreateLanguagedObject[] = [];
    data.descriptions.forEach((element) => {
      var description = new CreateLanguagedObject();
      description = element;
      descriptions.push(description);
    });
    model.descriptions = descriptions;

    var photos: CreatePhoto[] = [];
    var photo = new CreatePhoto();
    photo = {
      data: data.photoUrl,
      extention: 'png',
      name: 'testname',
      orderId: 1,
    };
    photos.push(photo);
    model.photos = photos;

    var titles: CreateLanguagedObject[] = [];
    var title1 = new CreateLanguagedObject();
    title1.value = 'სათაური';
    title1.languageId = 1;
    titles.push(title1);
    var title2 = new CreateLanguagedObject();
    title2.value = 'Title';
    title2.languageId = 2;
    titles.push(title2);
    model.titles = titles;
    this.instalmentService.createInstalment(model);
  }
}
