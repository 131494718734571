import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-employee-create',
  templateUrl: './employee-create.component.html',
  styleUrls: ['./employee-create.component.scss'],
})
export class EmployeeCreateComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {}

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      orderId: '',

      fullNames: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
      positions: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
      photos: this.fb.array([
        this.fb.group({
          data: 'string',
          name: 'string',
          extention: 'png',
          orderId: 1,
        }),
      ]),
    });
  }

  get fullNames(): FormArray {
    return <FormArray>this.form.get('fullNames');
  }
  photoUrl;
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.form.controls.photos.patchValue([{ data: base64File }]);
    console.log(base64File);
  }

  onSubmit(data) {
    this.employeeService.createEmployee(data);
  }
}
