<section>
<div class="image-upload">
  <img [src]="photoUrl" class="banner"/>
  
</div> 
</section>

<section id="About-update">
<input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
<section *ngFor="let shortDescForm of shortDescForms, let i = index">
<form style="margin-bottom: 30px;" [formGroup]="shortDescForm" (ngSubmit)="onShortDescriptionUpdate(shortDescForm)">
  <div class="flex">
    <div>
      <p>მოკლე აღწერა - {{shortDescForm?.controls?.language?.value?.name}}<p>

      <div id="quill">
        <quill-editor formControlName="value">
        </quill-editor>
      </div>

    </div>
  </div>
  <button style="font-size: 16px" mat-stroked-button type="submit">
    რედაქტირება
  </button>
</form>
</section>

<section *ngFor="let descForm of descForms">
<form style="margin-bottom: 30px;" [formGroup]="descForm" (ngSubmit)="onDescriptionUpdate(descForm)">
    <div>
      <p>აღწერა - {{descForm?.controls?.language?.value?.name}}</p>

      <div id="quill">
        <quill-editor formControlName="value">
        </quill-editor>
      </div>

    </div>
    <button style="font-size: 16px" mat-stroked-button type="submit">
    რედაქტირება
  </button>
</form>
</section>

</section>

