import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/shared-services/language.service';
import { environment } from 'src/environments/environment';
import { CreateEmployee, IEmployee } from '../Interfaces/employee';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  host = 'Employees';
  currentLanguage;

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }



  createEmployee(data: CreateEmployee) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`, { orderId: data.orderId, fullNames: data.fullNames, positions: data.positions, photos: data.photos }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  getEmployeeList() {
    return this.httpClient.get<IEmployee[]>(`${environment.BaseUrl}/${this.host}`).pipe(map((
      products: Array<IEmployee>) => {
      return products.map((employee) => {
        //  var productInfo = new ProductList();
        var employeeInfo;
        return employeeInfo = {
          id: employee.id,
          orderId: employee.orderId,
          fullName: employee.fullNames.find(x => x.language.id == this.currentLanguage)?.value ?? '',
          position: employee.positions.find(x => x.language.id == this.currentLanguage)?.value ?? '',

          photoUrl: employee.photos.length > 0
            ? environment.BaseUrl + '/' + this.host + '/' +
            employee.id +
            '/Photos?OrderId=' + employee.photos[0].orderId
            : '',
        }
      })
    }
    ));

  }


  deleteEmployeeById(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
    //.pipe(
    //  tap(() =>  console.log("error occured!"))

    // catchError(error => of(`Bad Promise: ${error}`))
    // );
  }


  getEmployeeById(id: number) {
    return this.httpClient
      .get<IEmployee>(`${environment.BaseUrl}/${this.host}/${id}`)
      .pipe(
        map((element) => {
          var Employee;
          return Employee = {
            id: element.id,
            orderId: element.orderId,
            fullNames: element.fullNames.map((x) => x ?? ''),
            positions: element.positions.map(m => m ?? ''),
            photoUrl: element.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/' + element.id + '/Photos?OrderId=' + element.photos[0].orderId : '',
            photoOrderId: element.photos[0].orderId,
          }
        })
      );
  }


  updateEmployeeFullName(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/FullNames`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  updateEmployeePosition(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Positions`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }



  updatePhoto(id, dataInfo) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/${id}/Photos`, {
      data: dataInfo.data,
      name: dataInfo.name,
      extention: dataInfo.extention,
      orderId: dataInfo.orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }



}
