import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BranchService } from '../services/branch.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {
  contactSubscription?: Subscription;

  constructor(private branchService: BranchService, private router: Router) {}
  ngOnDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

  branchList = [];
  ngOnInit(): void {
    this.contactSubscription = this.branchService
      .getBrancheList()
      .subscribe((data) => {
        data.forEach((element) => {
          this.branchList.push(element);
        });
      });
  }

  redirectToCreateContact() {
    this.router.navigateByUrl('contact-create');
    console.log('bnm');
  }
  removebranch(id: number) {
    this.branchService.deleteBranchById(id);
  }
}
