<section id="display-size">
    <div class='card'>
        <div class='card-header'>
            <p id="p1">

                Instalment List
            </p>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-md-2'>
                    <button style="margin-bottom: 20px" (click)="redirectToCreateInstalment()">Create New
                        Instalment</button>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>

                        <th>ID</th>

                        <th>
                            Description
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let instalment of instalmentList;  let i=index'>
                        <th>{{instalment.id}}</th>
                        <th> {{instalment.descriptions}}</th>

                        <th>
                            <img style="width: 80px;" [src]='instalment.photoUrl'>
                        </th>
                        <th>
                            <button [routerLink]="['/instalment-update', instalment.id]"> EDIT</button>
                        </th>
                        <th>
                            <button (click)="removeInstalment(instalment.id)"> DELETE</button>
                        </th>

                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</section>