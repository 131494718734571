<section>
  <div class="image-upload">
 <img [src]="photoUrl" class="banner"/>
 </div> 
</section>

<section id="Service-update">
<input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
<section *ngFor="let descForm of descForms" >
<form style="margin-bottom: 30px;" [formGroup]="descForm" (ngSubmit)="onDescriptionUpdate(descForm)">
<div class="flex">
<div>
<p>აღწერა - {{descForm?.controls?.language?.value?.name}}</p>

<div id="quill">
<quill-editor formControlName="value">
</quill-editor>
</div>

</div>
</div>
<button style="font-size: 16px" mat-stroked-button type="submit">
რედაქტირება
</button>
</form>
</section>
</section>
