import { ILanguage } from "./language.interface";

export interface IDescription {
  value: string;
  language: ILanguage;
}

export class CreateLanguagedObject{
  value: string;
  languageId: number;
}