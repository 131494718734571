import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColorService } from '../services/color.service';

@Component({
  selector: 'app-color-update',
  templateUrl: './color-update.component.html',
  styleUrls: ['./color-update.component.scss'],
})
export class ColorUpdateComponent implements OnInit, OnDestroy {
  id;
  colorSubscription?: Subscription;

  nameForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private colorService: ColorService
  ) {}
  ngOnDestroy(): void {
    this.colorSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.nameForm = this.fb.group({
      names: this.fb.array([]),
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getColorById(this.id);
  }

  getColorById(id: number) {
    this.colorSubscription = this.colorService
      .getColorById(id)
      .subscribe((data) => {
        data.names.forEach((element) => {
          this.addName(element);
        });
      });
  }

  //name
  get names(): FormArray {
    return <FormArray>this.nameForm.get('names');
  }

  addName(data): void {
    this.names.push(this.buildNames(data));
  }

  buildNames(data): FormGroup {
    return this.fb.group({
      value: data.value,
      language: data.language,
    });
  }

  languageId;
  getFormLanguageId(id: number) {
    this.languageId = id;
  }

  editName(data) {
    var model = data.names?.find((x) => x.language.id == this.languageId);

    var updateModel = {
      value: model?.value,
      languageId: model?.language?.id,
    };

    this.colorService.updateColor(this.id, updateModel);

    // this.fetchAboutInfo();
  }
}
