import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceService } from '../services/service.service'
import { TitlesService } from '../services/titles.service';
import { Service } from '../shared/models/service.model';
@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent implements OnInit, OnDestroy {
  titles;
  serviceSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private serviceService: ServiceService,
    private titlesService: TitlesService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.fetchService();
    this.titles = this.titlesService.getTitles();
  }

  serviceInfo?: Service;
  fetchService() {
    this.serviceSubscription = this.serviceService.getService().subscribe((data) => {
      this.serviceInfo = {
        description: data.description,
        photoUrl: data.photoUrl,
      } }
    )
    this.subscriptions.push(this.serviceSubscription);
  }
}
