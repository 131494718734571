export class Product {
  totalPages?: number;
  id: number;
  photoUrl: string;
  name: string;
  salePercentage?: number;
  showSalePercentage?: boolean;
  originalValue?: number;
  saledValue?: number;
  saleValue?: number;
}
