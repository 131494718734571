import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContactsService } from '../services/contacts.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit, OnDestroy {
  contactSubscription?: Subscription;

  contactsForm;
  photoForm;
  constructor(
    private fb: FormBuilder,
    private contactsService: ContactsService
  ) {}
  ngOnDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.contactsForm = this.fb.group({
      facebookUserId: '',
      facebook: '',
      instagram: '',
      youtube: '',
    });
    this.photoForm = this.fb.group({
      data: 'string',
      name: 'string',
      extention: 'png',
      orderId: null,
    });

    this.getContacts();
  }

  getContacts() {
    this.contactSubscription = this.contactsService
      .fetchContact()
      .subscribe((element) => {
        this.contactsForm.setValue({
          facebookUserId: element.facebookUserId,
          facebook: element.facebook,
          instagram: element.instagram,
          youtube: element.youtube,
        });
        this.photoForm.patchValue({
          orderId: element.photoOrderId,
        });

        this.photoUrl = element.photoUrl;
      });
  }

  editContacts(data) {
    this.contactsService.updateContacts(data);
  }

  //photo
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  photoUrl;
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    // this.photoForm.patchValue([
    //   { data: base64File }
    // ])
    this.photoForm.controls.data.setValue(base64File);
    console.log(this.photoForm.get('data'));
    console.log(base64File);

    this.contactsService.updatePhoto(this.photoForm.value);
  }
}
