<!-- <form  [formGroup]="inputForm"
(ngSubmit)="onSubmit(inputForm.value)">

<mat-form-field class="example-full-width" appearance="outline">
  <mat-label>User</mat-label>
  <input formControlName="user" matInput required /> -->
<!-- <div
    *ngIf="
    user.hasError('required') &&
      (user.dirty || user.touched)"
    style="color: red"
  >
    This field is required
  </div>
  <div
    *ngIf="user.hasError('pattern') &&
      (user.dirty || user.touched)
    "
    style="color: red"
  >
    Invalid UserName
  </div> -->
<!-- </mat-form-field>
<mat-form-field class="example-full-width" appearance="outline">
  <mat-label>Password</mat-label>
  <input formControlName="password" matInput required /> -->
<!-- <div
    *ngIf="
    password.hasError('required') &&
      (password.dirty || password.touched)
    "
    style="color: red"
  >
    This field is required
  </div>
  <div
    *ngIf="
    password.hasError('pattern') &&
      (password.dirty || password.touched)
    "
    style="color: red"
  >
    Invalid Password
  </div> -->
<!-- </mat-form-field>

<button

style="font-size: 16px"
mat-stroked-button
type="submit"
[disabled]="inputForm.invalid"
>

  Submit

</button>

</form> -->













<form [formGroup]="inputForm" (ngSubmit)="onSubmit(inputForm.value)">
  <mat-form-field id="InputL" class="example-full-width" appearance="outline">
    <mat-label>User</mat-label> <input formControlName="user" matInput required />
    <!-- <div *ngIf=" user.hasError('required') && (user.dirty || user.touched)" style="color: red" > This field is required </div> <div *ngIf="user.hasError('pattern') && (user.dirty || user.touched) " style="color: red" > Invalid UserName </div> -->
  </mat-form-field> <br>
  <mat-form-field id="InputL" class="example-full-width" appearance="outline">
    <mat-label>Password</mat-label>
    <input formControlName="password" matInput required />
    <!-- <div *ngIf=" password.hasError('required') && (password.dirty || password.touched) " style="color: red" > This field is required </div> <div *ngIf=" password.hasError('pattern') && (password.dirty || password.touched) " style="color: red" > Invalid Password </div> -->
  </mat-form-field> <br> 
  <button style="font-size: 16px" mat-stroked-button type="submit"
    [disabled]="inputForm.invalid"> Submit </button>
</form>