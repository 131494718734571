import { Injectable } from '@angular/core';
import { LanguageService } from './shared-services/language.service';
import { navbar } from './../shared/staticData/navbar';
@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  currentLanguage;

  constructor(private languageService: LanguageService) {

    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }


  //first parameter in Georgian, second in English
  //  navbarList={
  //   main: ['SkipSelf', 'DESCRIPTION'],
  //   about: ['s', 'ABOUT US'],
  //   prods: ['d', 'PRODUCTS'],
  //   service: ['k', 'SERVICES'],
  //   instalments: ['d', 'INSTALMENT'],
  //   contacts: ['d', 'CONTACT US']
  //   }

  navbarList = [{
    'main': 'მთავარი',
    'about': 'ჩვენს შესახებ',
    'prods': 'პროდუქტები',
    'service': 'სერვისები',
    'instalments': 'განვადება',
    'contacts': 'კონტაქტი',
  },
  {
    'main': 'HOME',
    'about': 'ABOUT US',
    'prods': 'PRODUCTS',
    'service': 'SERVICES',
    'instalments': 'INSTALMENT',
    'contacts': 'CONTACT US',
  }

  ];

  navbar;

  getNavbar() {

         if(this.currentLanguage==1) {
return this.navbar=this.navbarList[0];
    }
  else{
    return  this.navbar=this.navbarList[1];
  }
}
}