<section id="display-size">
  <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">

    <div class="image-upload">
      <img [src]="photoUrl" />
      <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
    </div>

    <p> Order Id: </p> <input id="input1" formControlName='orderId'>
    <div formArrayName="titles">
      <p> დასახელება</p>
      <div [formGroupName]=0>
        <input id="input1" formControlName="value">

      </div>
      <p>TITLE</p>
      <div [formGroupName]=1>
        <input id="input1" formControlName="value">

      </div>
    </div>

    <div formArrayName="descriptions">
      <p> სლოგანი</p>
      <div [formGroupName]=0>
        <input id="input1" formControlName="value">

      </div>
      <p>Slogan</p>
      <div [formGroupName]=1>
        <input id="input1" formControlName="value">

      </div>
    </div>

    <button type="submit" id="SButton">
      Submit
    </button>
  </form>
</section>