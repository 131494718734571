<section class='prod-details'>
  <div class="title-text">
    <h3><b>{{product?.name}}</b></h3>
  </div>

  <div fxLayout="row wrap">
    <div class="address" fxFlex.lt-md="0 1 calc(100%)" fxFlex.md="0 1 calc(40%)" fxFlex.gt-md="0 1 calc(40%)">
      <div class="wrap" ngClass.lt-lg="xs-wrap">

        <div ngClass.gt-md="gt-md-MainPhoto" ngClass.md='md-MainPhoto' ngClass.lt-md='lt-md-MainPhoto'>
          <div class="prod" ngClass.sm='sm-prod' ngClass.xs='xs-prod'>
            <img id="ImgBigDoor" [src]="product?.photoUrl" alt="MainDoor">
          </div>
        </div>
        <div ngClass.gt-sm="gt-sm-OtherPhotoes" ngClass.md='md-OtherPhotoes' ngClass.sm='sm-OtherPhotoes'
          ngClass.xs='xs-OtherPhotoes'>
          <div class="OtherLeft" ngClass.lt-md="sm-OtherLeft"> <img id="ImgDoor" [src]="product?.photoUrl" alt="Door">
          </div>
          <div class="OtherRight" ngClass.lt-md='sm-OtherRight'> <img id="ImgDoor" [src]="product?.photoUrl" alt="Door">
          </div>
        </div>

      </div>
    </div>

    <div class="address" fxFlex.lt-md="0 1 calc(100%)" fxFlex.md="0 1 calc(60%)" fxFlex.gt-md="0 1 calc(60%)">
      <div class="wrap" ngClass.lt-lg="xs-wrap">

        <div class="TextMainDiv" ngClass.lt-md="lt-md-TextMainDiv">
          <div ngClass.gt-sm="gt-sm-TextTitles" ngClass.lt-md='lt-md-TextTitles'>
            <div id="NameTitle">
              <p><b>{{productDetailsData.title}}</b></p>
            </div>
            <div id="SizeTitle">
              <p><b>{{productDetailsData.size}}:</b></p>
            </div>
            <div id="DescriptionTitle">
              <p><b>{{productDetailsData.description}}:</b></p>
            </div>
            <div id="CompositionTitle">
              <p><b>{{productDetailsData?.compositions}}:</b></p>
            </div>
            <div id="ColorTitle">
              <p><b>{{productDetailsData?.color}}:</b></p>
            </div>
            <div id="PriceTitle">
              <p><b>{{productDetailsData?.price}}:</b></p>
            </div>
          </div>
          <div ngClass.gt-sm="gt-sm-Text">
            <div id="NameText">
              <p>{{product?.id}}</p>
            </div>
            <div id="SizeText">
              <p>{{product?.width}}x{{product?.height}}</p>
            </div>
            <div id="DescriptionText" [innerHtml]='product?.description'>
              <!-- <p>{{product?.description}} </p> -->
            </div>
            <div id="CompositionText">
              <p>{{product?.compositions}}</p>
            </div>
            <div id="ColorTitle">
              <p>{{product?.colors}}</p>
            </div>
            <div id="PriceText">
              <p> <span [ngClass]="product.saleValue || product.salePercentage ? 'strikethrough' : 'null'"
                  style="margin-right: 15%;"> {{product.originalValue | currency:'GEL':'symbol-narrow'}}</span>
                <span *ngIf="product.saleValue || product.salePercentage" style="color: red;">
                  {{product.saledValue | currency:'GEL':'symbol-narrow' }}
                </span>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="calcCounter" ngClass.md="md-calcCounter" ngClass.lt-md="lt-md-calcCounter">
    <form class='form' ngClass.xs='xs-form' [formGroup]='form' (ngSubmit)="calculatePrice(null, form.value)">
      <input placeholder='Width' formControlName='width'>
      <input placeholder='Height' formControlName='height'>
      <button type='submit'>Calculate</button>

    </form>
    <p *ngIf='show' ngClass.xs='xs-calclPrice'><b> Calculated Price:</b>
      {{calculatedPrice?.saledValue | currency:'GEL':'symbol-narrow'}}
    </p>
  </div>
</section>