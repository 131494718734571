<section id="Composition-create" id="display-size">
    <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">
        <div formArrayName="names">
            <p>დასახელება </p>
            <div [formGroupName]=0>
                <input formControlName="value">
            </div>
            <p>Name</p>
            <div [formGroupName]=1>
                <input formControlName="value">
            </div>
        </div>
        <button type="submit" id="SButton">
            Submit
        </button>
    </form>
</section>