<section id="Product-create">
  <form [formGroup]='form' (ngSubmit)="onSubmit(form.value)">
    <div class="image-upload">
      <div formArrayName="photos">
        <div [formGroupName]=0>
          <img [src]="photoUrl" />
          <input id="Choosefile" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" /> 
        </div>
      </div>
    </div>

    <div formArrayName="names">
      <p> დასახელება</p>
      <div [formGroupName]=0>
        <input id="input1" formControlName="value">
      
      </div>
      <p>TITLE</p>
      <div [formGroupName]=1>
        <input id="input1" formControlName="value">
       
      </div>
    </div>

    <div formArrayName="descriptions">
      <p>აღწერა </p>
      <div [formGroupName]=0>
        <quill-editor formControlName="value">
        </quill-editor>
      </div>
      <p>DESCRIPTION</p>
      <div [formGroupName]=1>
        <quill-editor formControlName="value">
        </quill-editor>
      </div>
    </div>


    <p>COLORS </p>

      <button id="PlusButton" (click)="addColor()"><i class="fa fa-plus" aria-hidden="true"></i></button> 
    <div formArrayName="colors" *ngFor="let color of colors.controls; let i=index">
      <div [formGroupName]=i>
        <select  class="custom-select" id="ColorDiv" formControlName="colorId">
          <option value="" disabled>Choose color</option>
          <option *ngFor="let color of colorList" [ngValue]="color.id">{{color.name}}</option>

        </select>
        <button (click)="removeColor(i)" id="TrashButton"><i class="fa fa-trash fa-lg" ></i></button>
      </div>
    </div>

 <br>
    <p>COMPOSITIONS </p>

    <button id="PlusButton" (click)="addComposition()"><i class="fa fa-plus" aria-hidden="true"></i></button>
    <div formArrayName="compositions" *ngFor="let composition of compositions.controls; let i=index">
      <div [formGroupName]=i>
        <select class="custom-select" id="ColorDiv" formControlName="compositionId">
          <option value="" disabled>Choose composition</option>
          <option *ngFor="let composition of compositionList" [ngValue]="composition.id">{{composition.name}}</option>
        </select>

        <button (click)="removeComposition(i)" id="TrashButton"><i class="fa fa-trash fa-lg" ></i></button>
      </div>
    </div>
<br>

    <p>
      DIMENTIONS

    </p>
    <div formGroupName=dimentions>
      <p id="DP">Width:</p> <input id="input1" type="number" formControlName="width">
      <p id="DP">Height:</p> <input id="input1" type="number" formControlName="height">
    </div>

    <p>PRICES</p>
    <div formGroupName=prices>
      <p id="DP"> original value: </p> <input id="input1" type="number" formControlName="originalValue">
      <p id="DP">percent: </p> <input id="input1" type="number" formControlName="salePercentage"> <br>
      <p id="DP"> show percentage value: </p> <input id="input1" type="boolean" formControlName="showSalePercentage"> 
      <p id="DP"> sale: </p> <input id="input1" type="number" formControlName="saleValue"> <br>
      <p id="DP"> saled: </p> <input id="input1" type="number" formControlName="saledValue">
    </div>

    <button type="submit" id="SButton">
      Submit
    </button>
  </form>

</section>

