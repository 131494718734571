import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { Product } from '../shared/models/product.model';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ColorService } from '../services/color.service';
import { Color } from '../shared/models/color.model';
import { Composition } from '../shared/models/composition.model';
import { CompositionService } from '../services/composition.service';

import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { TitlesService } from '../services/titles.service';
import { FilterDataService } from '../services/filter-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit, OnDestroy {

  features: FormGroup;

  productTitle: string = 'პროდუქტები';
  title;
  endPrice: number;
  startPrice: number;
  colorIds: number[] = [];
  compositionIds: number[] = [];
  filterByTitleSubscription?: Subscription;
  filterByToSubscription?: Subscription;
  filterByFromSubscription?: Subscription;
  filterByColorsSubscription?: Subscription;
  filterByCompositionsSubscription?: Subscription;
  colorsSubscription?: Subscription;
  compositionsSubscription?: Subscription;
  productsSubscription?: Subscription;
  subscriptions: Subscription[] = [];


  //for language change
  titles;
  filterData;


  constructor(
    private productService: ProductService,
    private compositionService: CompositionService,
    private colorService: ColorService,
    private fb: FormBuilder,
    private titlesService: TitlesService,
    private filterDataService: FilterDataService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    //this.getPros();
    //this.watchPages();
    // / this.checkPages()
    this.fetchColors();
    this.fetchCompositions();
    this.titles = this.titlesService.getTitles();
    this.filterData = this.filterDataService.getFilterData();

    this.features = this.fb.group({
      title: '',
      to: null,
      from: '',
      colors: this.fb.array([]),
      compositions: this.fb.array([]),
    });
    //watch for changes in from values
    this.watch();




  }




  products: Product[] = [];

  fetchProducts(colorIds?: number[], compositionIds?: number[], startPrice?: number, endPrice?: number, title?: any, pageNumber?: number, pageSize?: number, clear = true ) {
   this.productsSubscription = this.productService.getProducts(colorIds, compositionIds, startPrice, endPrice, title, pageNumber, pageSize).subscribe((data) => {
      if(clear){
        this.products = [];
      }
      data.forEach((element) => {
        this.products.push(
          element
        )
      })
    })
    this.subscriptions.push(this.productsSubscription);
  }



  show=true;
  index = 1;
  showMore() {
    this.index += 1;
    this.checkPages();
    if(this.index==this.products[0].totalPages){
      this.show=false;
    }
  }


  checkPages() {
    this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9, false)
  }



  watch() {
    
    this.products = [];
    this.filterByTitleSubscription = this.features.get('title').valueChanges.pipe(
      map((titleInput) => {
        this.title = titleInput;
        this.index = 1;
        this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9)
      })

    ).subscribe();

    this.products = [];
    this.filterByFromSubscription = this.features.get('from').valueChanges.pipe(
      map((start) => {
        this.startPrice = start;
        this.index = 1;
        this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9)
      })

    ).subscribe();

    this.products = [];
    this.filterByToSubscription = this.features.get('to').valueChanges.pipe(
      map((end) => {
        this.endPrice = end;
        this.index = 1;
        this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9)
      })

    ).subscribe();

    this.products = [];
    this.filterByColorsSubscription = this.features.get('colors').valueChanges.pipe(
      map((colors) => {
        this.index = 1;
        this.colorIds = [];
        for (let i = 0; i < colors.length; i++) {
          if (colors[i].value == true) {
            this.colorIds.push(this.col[i].id);
          }
        }
        this.products = [];
        this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9);
      })).subscribe()

      this.products = [];
      this.filterByCompositionsSubscription =  this.features.get('compositions').valueChanges.pipe(
      map((compositions) => {
        this.index = 1;
        this.compositionIds = [];
        for (let i = 0; i < compositions.length; i++) {
          if (compositions[i].value == true) {
            this.compositionIds.push(this.comp[i].id);
          }
        }
        this.products = [];
      this.fetchProducts(this.colorIds, this.compositionIds, this.startPrice, this.endPrice, this.title, this.index, 9);
      })).subscribe();

       this.subscriptions.push(this.filterByTitleSubscription,
        this.filterByToSubscription,
        this.filterByFromSubscription,
        this.filterByColorsSubscription,
        this.filterByCompositionsSubscription);
  }

  addColors(): void {
    this.colors.push(this.buildColors());
  }

  buildColors(): FormGroup {
    return this.fb.group({
      value: false,
    })
  }

  get colors(): FormArray {
    return <FormArray>this.features.get('colors');
  }

  addCompositions(): void {
    this.compositions.push(this.buildCompositions());
  }

  buildCompositions(): FormGroup {
    return this.fb.group({
      value: false,
    })
  }

  get compositions(): FormArray {
    return <FormArray>this.features.get('compositions');
  }









  col: Array<Color> = [];
  fetchColors() {
  this.colorsSubscription = this.colorService.getColors().subscribe((data) => {
      data.forEach((element) => {

        this.addColors();

        console.log(element);
        this.col?.push(element);
      })
    });
    this.subscriptions.push(this.colorsSubscription);
  }

  comp: Array<Composition> = [];
  fetchCompositions() {
  this.compositionsSubscription = this.compositionService.getCompositions().subscribe((data) => {
      data.forEach((element) => {
        this.addCompositions();
        this.comp?.push(element);
      })

    });
    this.subscriptions.push(this.compositionsSubscription);
  }

  isOpen = false;



  // products: Product[] = [];

  // getPros(index?: number) {
  //   this.productService.getPros(index, 9).subscribe
  //     (
  //       (data) => {

  //         data.forEach((element) => {
  //           this.products.push(
  //             element
  //           )
  //         })
  //       }
  //     );

  // }

}





