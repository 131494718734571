import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AboutService } from '../services/about.service';

@Component({
  selector: 'app-about-update',
  templateUrl: './about-update.component.html',
  styleUrls: ['./about-update.component.scss'],
})
export class AboutUpdateComponent implements OnInit, OnDestroy {
  aboutSubscription?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private aboutService: AboutService
  ) {}

  ngOnDestroy(): void {
    this.aboutSubscription.unsubscribe();
  }

  descForms = [];
  shortDescForms = [];
  photoUrl;

  ngOnInit(): void {
    this.fetchAboutInfo();
  }

  fetchAboutInfo(): void {
    this.aboutSubscription = this.aboutService
      .getAbout()
      .subscribe((element) => {
        element.descriptions.forEach((x) => {
          const descForm = this.formBuilder.group({
            value: x.value,
            language: x.language,
          });

          descForm.controls.value.setValue(x.value);
          descForm.controls.language.setValue(x.language);
          this.descForms.push(descForm);
        });

        element.shortDescriptions.forEach((x) => {
          const shortDescForm = this.formBuilder.group({
            value: x.value,
            language: x.language,
          });

          shortDescForm.controls.value.setValue(x.value);
          shortDescForm.controls.language.setValue(x.language);
          this.shortDescForms.push(shortDescForm);
        });

        this.photoUrl = element.photoUrl;
      });
  }

  onShortDescriptionUpdate(form): void {
    // window.location.reload();
    const updateModel = {
      value: form.controls.value.value,
      languageId: form.controls.language.value.id,
    };
    this.aboutService.UpdateAboutShortDescription(updateModel);
    this.descForms = [];
    this.shortDescForms = [];
    this.fetchAboutInfo();
  }

  onDescriptionUpdate(form): void {
    const updateModel = {
      value: form.controls.value.value,
      languageId: form.controls.language.value.id,
    };
    this.aboutService.UpdateAboutDescription(updateModel);
    this.descForms = [];
    this.shortDescForms = [];
    this.fetchAboutInfo();
  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.photoUrl = reader.result;
    const base64File = reader.result.split(',')[1];
    console.log(base64File);
    var photo = {
      data: base64File,
      name: 'string',
      extention: 'string',
      orderId: 0,
    };
    this.aboutService.updatePhoto(photo);
  }
}
