import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BranchService } from '../services/branch.service';

@Component({
  selector: 'app-contact-create',
  templateUrl: './contact-create.component.html',
  styleUrls: ['./contact-create.component.scss'],
})
export class ContactCreateComponent implements OnInit {
  form;
  constructor(private fb: FormBuilder, private branchService: BranchService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      orderId: '',
      isMain: false,
      titles: this.fb.array([
        this.fb.group({
          value: '',
          languageId: 1,
        }),
        this.fb.group({
          value: '',
          languageId: 2,
        }),
      ]),
      addresses: this.fb.array([
        this.fb.group({
          orderId: null,
          latitude: '',
          longitude: '',
          values: this.fb.array([
            this.fb.group({
              languageId: 1,
              value: '',
            }),
            this.fb.group({
              languageId: 2,
              value: '',
            }),
          ]),
        }),
        // this.fb.group({
        //   orderId: null,
        //   latitude: '',
        //   longitude: '',
        //   values: [
        //     {
        //       languageId: 2,
        //       value: ''
        //     }
        //   ]
        // }),
      ]),
      workingHours: this.fb.array([
        this.fb.group({
          value: '',
          orderId: null,
        }),
      ]),
      emails: this.fb.array([
        this.fb.group({
          value: '',
          orderId: null,
        }),
      ]),
      phoneNumbers: this.fb.array([
        this.fb.group({
          value: '',
          orderId: null,
        }),
      ]),
      photos: this.fb.array([
        this.fb.group({
          data: 'string',
          name: 'string',
          extention: 'png',
          orderId: 0,
        }),
      ]),
    });
  }
  //address
  get addresses(): FormArray {
    return <FormArray>this.form.get('addresses');
  }

  getAddresses(form) {
    console.log(form.get('addresses')?.controls);
    return form?.controls?.addresses?.controls;
  }

  getValues(form) {
    //  console.log(form.get('values')?.controls);
    return form?.controls?.values?.controls;
  }

  //phone Numbers
  get phoneNumbers(): FormArray {
    return <FormArray>this.form.get('phoneNumbers');
  }
  addPhoneNum(): void {
    this.phoneNumbers.push(this.buildPhoneNumbers());
  }

  removePhoneNum(id: number) {
    this.phoneNumbers.removeAt(id);
  }

  buildPhoneNumbers(): FormGroup {
    return this.fb.group({
      value: '',
      orderId: null,
    });
  }

  //email
  get emails(): FormArray {
    return <FormArray>this.form.get('emails');
  }
  addEmail(): void {
    this.emails.push(this.buildEmail());
  }

  removeEmail(id: number) {
    this.emails.removeAt(id);
  }

  buildEmail(): FormGroup {
    return this.fb.group({
      value: '',
      orderId: null,
    });
  }

  //Working Hours
  get workingHours(): FormArray {
    return <FormArray>this.form.get('workingHours');
  }
  addworkingHour(): void {
    this.workingHours.push(this.buildworkingHour());
  }

  removeworkingHour(id: number) {
    this.workingHours.removeAt(id);
  }

  buildworkingHour(): FormGroup {
    return this.fb.group({
      value: '',
      orderId: null,
    });
  }

  //photos
  photoUrl;
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    var picToShow = reader.result;
    this.photoUrl = picToShow;

    const base64File = reader.result.split(',')[1];
    this.form.controls.photos.patchValue([{ data: base64File }]);
    console.log(base64File);
  }

  onSubmit(data) {
    this.branchService.createBranch(data);
  }
}
