import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IBranch, UpdateAddress } from '../Interfaces/branch.interface';
import { LanguageService } from 'src/app/services/shared-services/language.service';
import { Observable } from 'rxjs';
import { Branch } from 'src/app/shared/models/branch.model';
import { CreateLanguagedObject } from '../Interfaces/description.interface';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})


export class BranchService {

  host = 'Branches';

  currentLanguage;
  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());
  }



  getBrancheList(): Observable<Branch[]> {
    return this.httpClient
      .get<IBranch[]>(`${environment.BaseUrl}/${this.host}?orderby=orderid enc`)
      .pipe(
        map((branches: Array<IBranch>) => {
          return branches.map((branch) => {
            var branchInfo = new Branch();
            return branchInfo = {
              id: branch.id,
              orderId: branch.orderId,
              isMain: branch.isMain,
              title: branch.titles.find((x) => x.language.id == this.currentLanguage)?.value ?? '',
              workingHours: branch.workingHours.map((m) => m.value ?? ''),
              emails: branch.emails.map((m) => m.value ?? ''),
              photoUrl:
                branch.photos.length > 0
                  ? environment.BaseUrl + '/' + this.host + '/'+
                  branch.id +
                  '/Photos?OrderId=' +
                  branch.photos[0].orderId
                  : '',
              address:
                branch.addresses[0]?.values.find((x) => x.language.id == this.currentLanguage)
                  ?.value ?? '',
              phoneNumbers: branch.phoneNumbers.map((m) => m.value ?? ''),
              latitude: Number(branch.addresses[0]?.latitude),
              longitude: Number(branch.addresses[0]?.longitude),
            };
          });
        })
      );
  }



  //branch: Branch;
  getBranchById(id: number) {
    return this.httpClient
      .get<IBranch>(`${environment.BaseUrl}/${this.host}/${id}`)
      .pipe(
        map((element) => {
          var branch;
          return branch = {
            id: element.id,
            orderId: element.orderId,
            isMain: element.isMain,
            titles: element.titles.map((x) => x ?? ''),
            workingHours: element.workingHours.map(m => m ?? ''),
            emails: element.emails.map(m => m ?? ''),
            photoUrl: element.photos.length > 0 ? environment.BaseUrl + '/' + this.host + '/'+ element.id + '/Photos?OrderId=' + element.photos[0].orderId : '',
            address: element.addresses[0],
            phoneNumbers: element.phoneNumbers.map(m => m ?? ''),
            photoOrderId: element.photos[0].orderId,
            latitude: element.addresses[0]?.latitude,
            longitude: element.addresses[0]?.longitude,
          }
        })
      );
  }


  deleteBranchById(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
    //.pipe(
    //  tap(() =>  console.log("error occured!"))

    // catchError(error => of(`Bad Promise: ${error}`))
    // );
  }


  updateBranchName(id, data) {
    const { value, languageId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Titles`;
    this.httpClient.put(url, { value, languageId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  // updateBranchAddress(id, data){}

  updateBranchAddress(id, data
    
     : UpdateAddress
    ) {
    //  const {  orderId,
    //   latitude,
    //  longitude,
    //  values: data.values} = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Addresses`;
    this.httpClient.put(url, {  orderId:data.orderId,
      latitude:data.latitude,
     longitude:data.longitude,
     values: data.values}, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  updateBranchWorkingHour(id, data) {
    const { value, orderId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/WorkingHours`;
    this.httpClient.put(url, { value, orderId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  updateBranchEmail(id, data) {
    const { value, orderId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/Emails`;
    this.httpClient.put(url, { value, orderId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });

  }



  updateBranchPhoneNum(id, data) {
    const { value, orderId, } = data;
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const url = `${environment.BaseUrl}/${this.host}/${id}/PhoneNumbers`;
    this.httpClient.put(url, { value, orderId }, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });

  }

  updatePhoto(id, dataInfo) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.put<any>(`${environment.BaseUrl}/${this.host}/${id}/Photos`, {
      data: dataInfo.value.data,
      name: dataInfo.value.name,
      extention: dataInfo.value.extention,
      orderId: dataInfo.value.orderId
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  deleteNumber(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}/PhoneNumbers`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  deleteEmail(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}/Emails`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  deleteHour(id: number) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}/WorkingHours`, { headers })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }



  createBranch(data: IBranch) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`, {
      orderId : data.orderId, addresses: data.addresses, workingHours: data.workingHours, titles: data.titles, emails: data.emails, phoneNumbers: data.phoneNumbers, photos: data.photos
    }, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


}