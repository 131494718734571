import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IColor, UpdateColor } from '../Interfaces/color.interface';
import { Color } from '../models/color.model';
import { LanguageService } from '../../../services/shared-services/language.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ColorService {
  currentLanguage;

  constructor(private httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.currentLanguage = Number(this.languageService.getCurrentLanguageId());

  }

  host = 'Colors';

  colorList: Color;
  getColors() {
    return this.httpClient.get(`${environment.BaseUrl}/${this.host}`).pipe(
      map((colors: Array<IColor>) => {
        return colors.map((color) => {
          // var colorList = new Color();
          return this.colorList = {
            id: color.id,
            name: color.names.find(x => x.language.id == this.currentLanguage)?.value ?? '',
          }
        }
        )
      }
      )
    )
  }



  postColor(data){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}/{data.id}/Names`, {colors: data.colors}, {headers}).subscribe({
      next: data => {
          console.log(data);
      },
      error: error => {
          console.error('There was an error!', error);
      }
  });
  }

getColorById(id: number){
  return this.httpClient
  .get<IColor>(`${environment.BaseUrl}/${this.host}/${id}`)
  .pipe(
    map((color) => {
      var Color;
      return Color= {
        id: color.id,
        names: color.names.map(x => x ?? '')
      }
    })
  )
}

updateColor(id, data) {
 const { value, languageId, } = data;
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const url = `${environment.BaseUrl}/${this.host}/${id}/Names`;
  this.httpClient.put(url,  data , { headers })
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
}


createColor(data: UpdateColor) {
     const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    this.httpClient.post(`${environment.BaseUrl}/${this.host}`,  data, {
      headers
    })
      .subscribe({
        next: x => {
          console.log(x);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }

  removeColor(id: number){
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return this.httpClient.delete(`${environment.BaseUrl}/${this.host}/${id}`, {headers})
    .subscribe({
      next: x => {
        console.log(x);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }
}
